import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Layout from "components/blogs/Layout";
import Main from "components/blogs/Main";
import "twin.macro";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header roundedHeaderButton />
      <Layout>
        <Main />
      </Layout>
      <Footer />
    </AnimationRevealPage>
  );
};
