import "styled-components/macro";
import React from "react";
import { ScrollView, Popup } from "devextreme-react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Editing,
  SearchPanel,
  ColumnChooser,
  TotalItem,
  Summary,
  RequiredRule,
} from "devextreme-react/data-grid";
import { useMutation } from "react-query";
import JobService from "service/jobService";
import tw from "twin.macro";
import { useAuth } from "context/auth";
import { showNotification } from "utils/notification";

const Attendance = ({ visible, jobData, onHide }) => {
  const addAttendance = useMutation((requestData) =>
    JobService.addAttendance(requestData)
  );

  const { getUser } = useAuth();

  const onRowUpdating = async ({ newData, oldData }) => {
    const request = {
      numberOfHours: newData.numberOfHours,
      date: oldData.date,
      jobId: jobData.jobId,
    };

    await addAttendance.mutateAsync(request, {
      onError: (error) => {
        showNotification(error, "error");
      },
      onSettled: () => {
        getUser();
      },
      onSuccess: () => {
        showNotification("Successful", "success");
      },
    });
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHide}
      title='Attendance'
      showCloseButton
      fullScreen
    >
      <ScrollView>
        <div css={tw`p-4`}>
          <h3 css={tw`text-lg`}>Attendance (Added by me)</h3>
          <small>Please add your attendance below </small>
          <DataGrid
            dataSource={jobData.attendance || []}
            keyExpr='id'
            showBorders
            wordWrapEnabled
            columnAutoWidth={false}
            showColumnLines
            showRowLines
            columnHidingEnabled
            rowAlternationEnabled
            allowColumnResizing
            onRowUpdating={onRowUpdating}
          >
            <Editing
              allowAdding={false}
              allowUpdating={true}
              allowDeleting={false}
              mode='row'
            />
            <FilterRow visible={true} />
            <ColumnChooser enabled={true} mode='select' allowSearch={true} />
            <SearchPanel visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 15]}
              showNavigationButtons={true}
              showInfo={true}
            />

            <Column dataField='date' allowEditing={false} />
            <Column dataField='numberOfHours' dataType='number'>
              <RequiredRule />
            </Column>
            <Summary>
              <TotalItem
                column='numberOfHours'
                displayFormat='Total Hours: {0}'
                summaryType='sum'
              />
            </Summary>
          </DataGrid>
        </div>

        <div css={tw`p-4`}>
          <h3 css={tw`text-lg`}>Attendance (Added by employer)</h3>
          <DataGrid
            dataSource={jobData.attendanceByEmployer.attendance || []}
            keyExpr='id'
            showBorders
            wordWrapEnabled
            columnAutoWidth={false}
            showColumnLines
            showRowLines
            columnHidingEnabled
            rowAlternationEnabled
            allowColumnResizing
            onRowUpdating={onRowUpdating}
          >
            <FilterRow visible={true} />
            <ColumnChooser enabled={true} mode='select' allowSearch={true} />
            <SearchPanel visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={5} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 10, 20]}
              showNavigationButtons={true}
              showInfo={true}
            />

            <Column dataField='date' allowEditing={false} />
            <Column dataField='numberOfHours' dataType='number'>
              <RequiredRule />
            </Column>
            <Summary>
              <TotalItem
                column='numberOfHours'
                displayFormat='Total Hours: {0}'
                summaryType='sum'
              />
            </Summary>
          </DataGrid>
        </div>
      </ScrollView>
    </Popup>
  );
};

export default Attendance;
