import {
  educationLevelData,
  userProfileFields,
  relationshipData,
  residenceStatusData,
  employmentStatusData,
} from "constant/data";

export const setUserToLocalStorage = (token) => {
  localStorage.setItem(`tp-token-worker`, token);
};
export const removeUserFromLocalStorage = () => {
  localStorage.removeItem(`tp-token-worker`);
};

export const getToken = () => localStorage.getItem(`tp-token-worker`);

export const filterRequestData = (keys, requestBody) => {
  const result = {};

  const body = JSON.parse(JSON.stringify(requestBody));

  for (let key in body) {
    if (keys.includes(key)) {
      result[key] = body[key];
    }
  }

  return result;
};

export const formatProfieData = (userProfile) => {
  let newData = {
    emergencyContact: {},
  };
  if (userProfile) {
    newData = filterRequestData(userProfileFields, userProfile);
    if (userProfile.education) {
      if (!educationLevelData.find((e) => e.value === userProfile.education)) {
        newData.education = "other";
        newData.educationOther = userProfile.education;
      } else {
        newData.educationOther = "";
      }
    } else {
      newData.education = "";
      newData.educationOther = "";
    }

    if (userProfile.employmentStatus) {
      if (
        !employmentStatusData.find(
          (e) => e.value === userProfile.employmentStatus
        )
      ) {
        newData.employmentStatus = "other";
        newData.employementOther = userProfile.employmentStatus;
      } else {
        newData.employementOther = "";
      }
    } else {
      newData.employmentStatus = "";
      newData.employementOther = "";
    }

    if (userProfile.residenceStatus) {
      if (
        !residenceStatusData.find(
          (r) => r.value === userProfile.residenceStatus
        )
      ) {
        newData.residenceStatus = "other";
        newData.residenceOther = userProfile.residenceStatus;
      } else {
        newData.residenceOther = "";
      }
    } else {
      newData.residenceStatus = "";
      newData.residenceOther = "";
    }

    if (
      userProfile.emergencyContact &&
      userProfile.emergencyContact.relationship
    ) {
      const relationship = userProfile.emergencyContact.relationship;
      if (!relationshipData.find((r) => r.value === relationship)) {
        newData.emergencyContact.relationship = "other";
        newData.relationshipOther = userProfile.relationshipOther;
      } else {
        newData.relationshipOther = "";
      }
    } else {
      newData.emergencyContact.relationship = "";
      newData.relationshipOther = "";
    }
  }
  return newData;
};

export const formartNumberUtil = (number, locale = "en-ca") => {
  return number.toLocaleString(locale);
};

export const getLastChar = (string) => string.substr(string.length - 1);

export const isRichTextEmpty = (value) =>
  value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
  !value.includes("<img");

export const getJobsByStatus = (jobs, status) => {
  const result = jobs.filter((job) => job.status === status);

  return {
    result,
    length: result.length,
  };
};

export const calculateArea = (myRadius) => {
  return myRadius * myRadius * Math.PI;
};

export const validateSIN = (sinNumber) => {
  const arrayOfDigits = Array.from(String(sinNumber), Number);
  const multiplier = [1, 2, 1, 2, 1, 2, 1, 2, 1];

  if (arrayOfDigits.length < 9 || arrayOfDigits.length > 0) return false;

  const newArr = arrayOfDigits.map((e, i) => {
    let mult = e * multiplier[i];
    const toArray = Array.from(String(mult), Number);
    if (toArray.length > 1) {
      mult = toArray[0] + toArray[1];
    }

    return mult;
  });

  const sum = newArr.reduce((prev, curr) => (prev += curr), 0);

  return sum % 10 === 0 ? true : false;
};
