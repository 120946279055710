import "styled-components/macro";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { getSimilarPosts, getRecentPosts } from "service/blogService";
import tw from "twin.macro";

const PostWidget = ({ categories, slug }) => {
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    if (slug) {
      getSimilarPosts(categories, slug).then((result) => {
        setRelatedPosts(result);
      });
    } else {
      getRecentPosts().then((result) => {
        setRelatedPosts(result);
      });
    }
  }, [slug, categories]);

  return (
    <div css={tw`bg-white shadow-lg p-8 pb-12 mb-8`}>
      <h3 css={tw`text-xl mb-8 font-semibold border-b pb-4`}>
        {slug ? "Related Posts" : "Recent Posts"}
      </h3>
      {relatedPosts.map((post, index) => (
        <div key={index} css={tw`flex items-center w-full mb-4`}>
          <div css={tw`w-16 flex-none`}>
            <img
              alt={post.title}
              height={40}
              width={60}
              // unoptimized
              css={tw`align-middle rounded-md`}
              src={post?.featuredImage?.url}
              loading='lazy'
            />
          </div>
          <div css={tw`flex-grow ml-4`}>
            <p css={tw`text-gray-600 font-thin `}>
              {moment(post.createdAt).format("MMM DD, YYYY")}
            </p>
            <Link to={`/post/${post.slug}`} css={tw`text-sm`} key={index}>
              {post.title}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostWidget;
