import "styled-components/macro";
import React from "react";
import Header from "./Header";
import tw from "twin.macro";

const Layout = ({ children }) => (
  <div className='blog-main' css={tw`mt-6`}>
    <Header />
    {children}
  </div>
);

export default Layout;
