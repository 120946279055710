import "styled-components/macro";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import tw from "twin.macro";

const FeaturedPostCard = ({ post }) => (
  <div css={tw`relative h-72 mr-5`}>
    <div
      css={tw`absolute bg-center bg-no-repeat bg-cover shadow-md inline-block w-full h-72`}
      style={{ backgroundImage: `url('${post.featuredImage?.url}')` }}
    />
    <div
      css={tw`absolute bg-center bg-gradient-to-b opacity-50 from-gray-400 via-gray-700 to-black w-full h-72`}
    />
    <div
      css={tw`flex flex-col  p-4 items-center justify-center absolute w-full h-full`}
    >
      <p
        css={tw`text-white mb-4 font-semibold text-xs`}
        className='text-shadow '
      >
        {moment(post.createdAt).format("MMM DD, YYYY")}
      </p>
      <p
        css={tw`text-white mb-4 font-semibold text-2xl text-center`}
        className='text-shadow '
      >
        {post.title}
      </p>
      {/* <div tw='flex items-center absolute  w-full justify-center'>
        <img
          alt={post.author.name}
          height='30px'
          width='30px'
          tw='align-middle rounded-full'
          src={post.author?.photo?.url}
        />
        <p
          tw='inline align-middle text-white  ml-2 font-medium'
          className='text-shadow'
        >
          {post.author.name}
        </p>
      </div> */}
    </div>
    <Link to={`/post/${post.slug}`}>
      <span css={tw`cursor-pointer absolute w-full h-full`} />
    </Link>
  </div>
);

export default FeaturedPostCard;
