import "styled-components/macro";
import React from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import tw from "twin.macro";
import { Button } from "devextreme-react";

const PostCard = ({ post }) => {
  const { push } = useHistory();

  return (
    <div css={tw`bg-white shadow-lg  p-0 lg:p-8 pb-12 mb-8`}>
      <div css={tw`relative overflow-hidden shadow-md pb-80 mb-6`}>
        <img
          src={post?.featuredImage?.url}
          alt=''
          css={tw`object-top absolute h-80 w-full object-cover shadow-lg sm:rounded-t-lg lg:rounded-lg`}
        />
      </div>

      <h1
        css={tw`transition duration-700 text-center mb-8 cursor-pointer hover:text-blue-500! text-3xl font-semibold capitalize`}
      >
        <Link to={`/post/${post.slug}`}>{post.title}</Link>
      </h1>
      <div
        css={tw`block lg:flex text-center items-center justify-center mb-8 w-full`}
      >
        <div
          css={tw`flex  justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8 items-center`}
        >
          <img
            alt={post.author.name}
            height='30px'
            width='30px'
            css={tw`align-middle rounded-md`}
            src={post?.author?.photo?.url}
          />
          <p
            css={tw`inline align-middle text-gray-700 ml-2 font-medium text-lg`}
          >
            {post.author.name}
          </p>
        </div>
        <div css={tw`font-medium text-gray-700`}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            css={tw`h-6 w-6 inline mr-2 text-primary-500`}
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
            />
          </svg>
          <span css={tw`align-middle`}>
            {moment(post.createdAt).format("MMM DD, YYYY")}
          </span>
        </div>
      </div>
      <p
        css={tw`text-center text-lg text-gray-700 font-normal px-4 lg:px-20 mb-8`}
      >
        {post.excerpt}
      </p>
      <div css={tw`text-center`}>
        <Button
          text='Continue Reading'
          type='default'
          onClick={() => push(`/post/${post.slug}`)}
          css={tw`!rounded-full`}
        />
        {/* <Link to={`/post/${post.slug}`}>
          <span tw='transition duration-500 ease-linear transform hover:-translate-y-1 inline-block bg-primary-500 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer'>
            Continue Reading
          </span>
        </Link> */}
      </div>
    </div>
  );
};
export default PostCard;
