import "styled-components/macro";
import React, { useState, useRef, useMemo, useEffect } from "react";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  PatternRule,
  NumericRule,
} from "devextreme-react/form";
import tw from "twin.macro";
import { Heading } from "./Profile";
import Image from "components/misc/Image";
import { useAuth } from "context/auth";
import avatar_img from "images/avatar.png";
// import PhoneInput from "react-phone-input-2";
import Dropzone from "react-dropzone-uploader";
import {
  accountTypeData,
  documentTypeData,
  educationLevelData,
  employmentStatusData,
  jobPrefrenceData,
  paymentTypeData,
  provinceArray,
  relationshipData,
  residenceStatusData,
  userProfileFields,
} from "constant/data";
import { useAppGlobalState } from "context/appGlobalState";
// import Alert from "components/misc/Alert";
import { Button } from "devextreme-react";
import ProfileDocData from "components/profile/ProfileDocData";
import countryList from "react-select-country-list";
import ProfileService from "service/profileService";
import { filterRequestData, getToken } from "utils";
import { showNotification } from "utils/notification";
import { API_URL } from "constant";
import { alert } from "devextreme/ui/dialog";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";

// import "react-phone-input-2/lib/style.css";

const ProfileForm = tw.form`mt-4`;

const ProfileImageContainer = tw.div`flex items-center justify-center flex-col space-y-2 border p-3 rounded-2xl shadow bg-gray-100`;

const ProfileSettings = () => {
  const [isChanged, setIsChange] = useState(false);
  const { user, getUser } = useAuth();

  const formRef = useRef(null);
  const statusRef = useRef(null);

  const {
    formData,
    // userPhone,
    // setUserPhone,
    setIdDocURLS,
    idDocURLS,
    setIsEdited,
    // emerPhone,
  } = useAppGlobalState();

  const countryoOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((co) => ({
          ...co,
          icon: `flag-icon flag-icon-${co.value.toLowerCase()}`,
        })),
    []
  );

  useEffect(() => {
    if (user?.profile) {
      const status = user?.profile?.profileStatus;
      let severity = "warn";
      let detail =
        "Profile is awaiting verification. Our team is working on that. It usually takes 1 working day if all documents are correct.";

      if (status === "active") {
        severity = "success";
        detail =
          "Profile is active and verified. You can start applying to tasks and earn money.";
      } else if (status === "de-activated") {
        severity = "error";
        detail =
          "Profile is currently de-activated. Please contact the support team for help.";
      }

      statusRef.current?.replace({
        severity,
        detail,
        sticky: true,
        closable: false,
      });
    }
  }, [user]);

  const getUploadParams = async ({ file }, type) => {
    let body = new FormData();

    if (type === "FILE") {
      let type = formData.documentType;

      if (formData.documentType === "Other") {
        type = formData.docTypeOther;
      }

      body.append("file", file);
      body.append("type", type);
      body.append("fileName", file.name);

      return {
        url: `${API_URL}/api/v1/user/profile/file`,
        headers: { token: getToken() },
        body,
      };
    } else {
      body.append("image", file);

      return {
        url: `${API_URL}/api/v1/user/profile/image`,
        headers: { token: getToken() },
        body,
      };
    }
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove, xhr }, status, _, type) => {
    if (status === "error_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "exception_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "aborted") {
      showNotification(`${meta.name}, upload failed...`, "error");
    }

    if (status === "done") {
      if (type === "FILE") {
        const { data } = JSON.parse(xhr.response);
        setIdDocURLS((pre) => [...pre, data]);
        showNotification(`${meta.name} uploaded successfully`, "success");
      } else {
        getUser(true);
        showNotification(`profile image updated successfully`, "success");
      }
      remove();
    }
  };

  const onFieldDataChanged = (e) => {
    if (
      e.dataField === "employmentStatus" ||
      e.dataField === "residenceStatus" ||
      e.dataField === "emergencyContact.relationship" ||
      e.dataField === "education" ||
      e.dataField === "documentType" ||
      e.dataField === "docTypeOther" ||
      e.dataField === "paymentType"
    ) {
      setIsChange(!isChanged);
    }
    setIsEdited(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationResult = formRef.current.instance.validate();
    // const emerPhone = localStorage.getItem(
    //   `${user?.username}-${user?.provider}-emerPhone`
    // );

    if (validationResult && !validationResult.isValid) {
      return;
    }

    // if (!userPhone || !emerPhone) {
    //   alert("Please add phone and emergency contact phone", "Notification");
    //   return;
    // }

    if (!idDocURLS.length) {
      alert("Please add at least one identification document", "Notification");
      return;
    }

    const authDocs = idDocURLS.filter(
      (e) => e.type !== "Resume" && e.type !== "Other"
    );

    if (!authDocs.length) {
      alert(
        "Please add at least one valid means identification document",
        "Notification"
      );
      return;
    }

    const values = filterRequestData(userProfileFields, formData);

    values.idDocURLS = idDocURLS;
    // values.phone = userPhone;
    // values.emergencyContact.phone = emerPhone;

    if (formData.education === "other") {
      values.education = formData.educationOther;
    }

    if (formData.employmentStatus === "other") {
      values.employmentStatus = formData.employementOther;
    }

    if (formData.residenceStatus === "other") {
      values.residenceStatus = formData.residenceOther;
    }

    if (formData.emergencyContact.relationship === "other") {
      values.emergencyContact.relationship = formData.relationshipOther;
    }

    try {
      if (user.profile) {
        await ProfileService.updateProfile(values, user.profile._id);
      } else {
        await ProfileService.createProfile(values);
      }
      getUser(true);
      setIsEdited(false);
      showNotification("Profile updated successfully", "success");
    } catch (error) {
      showNotification(error, "error");
      setIsEdited(false);
    }
  };

  const profileImageRender = () => {
    return (
      <ProfileImageContainer className='profile-img'>
        <div className='circular--portrait'>
          <Image src={user?.profilePicUrl || avatar_img} />
        </div>
        <Dropzone
          getUploadParams={(fileWithMeta) =>
            getUploadParams(fileWithMeta, "IMAGE")
          }
          onChangeStatus={(fileWithMeta, status, fileWithMetaArray) =>
            handleChangeStatus(fileWithMeta, status, fileWithMetaArray, "IMAGE")
          }
          canRemove={true}
          maxSizeBytes={7340032}
          multiple={false}
          maxFiles={1}
          inputContent='Click here to change profile picture'
          accept='image/*'
        />
      </ProfileImageContainer>
    );
  };

  // const renderNumberInput = () => {
  //   return (
  //     <PhoneInput
  //       country='ca'
  //       value={userPhone}
  //       onlyCountries={["ca"]}
  //       // enableSearch
  //       // enableAreaCodes
  //       // enableTerritories
  //       // countryCodeEditable={false}
  //       inputProps={{
  //         name: "Phone *",
  //         required: true,
  //       }}
  //       onChange={(_value, _data, _e, formattedValue) => {
  //         setUserPhone(formattedValue);
  //         setIsEdited(true);
  //       }}
  //     />
  //   );
  // };

  // const renderEmerNumberInput = () => {
  //   return (
  //     <PhoneInput
  //       country='ca'
  //       value={emerPhone}
  //       enableSearch
  //       // enableAreaCodes
  //       // enableTerritories
  //       // countryCodeEditable={false}
  //       inputProps={{
  //         name: "Phone *",
  //         required: true,
  //       }}
  //       onChange={(_value, _data, _e, formattedValue) => {
  //         console.log({ formattedValue });
  //         localStorage.setItem(
  //           `${user?.username}-${user?.provider}-emerPhone`,
  //           formattedValue
  //         );
  //       }}
  //     />
  //   );
  // };

  const renderDocUpload = () => {
    return (
      <div css={tw`mt-3`}>
        <Dropzone
          getUploadParams={(fileWithMeta) =>
            getUploadParams(fileWithMeta, "FILE")
          }
          onChangeStatus={(fileWithMeta, status, fileWithMetaArray) =>
            handleChangeStatus(fileWithMeta, status, fileWithMetaArray, "FILE")
          }
          canRemove={true}
          multiple={false}
          maxSizeBytes={10485760}
          maxFiles={1}
          inputContent='Drag & Drop your file here or click to browse'
          disabled={
            !formData.documentType ||
            (formData.documentType === "Other" && !formData.docTypeOther)
          }
        />
      </div>
    );
  };

  return (
    <div>
      <Heading>Profile Settings</Heading>
      <Messages ref={statusRef} />
      {/* <Alert>
        <span>
          Don't forget to click on the <strong>Save Changes</strong> button to
          save your changes.
        </span>
      </Alert> */}
      <Message
        severity='info'
        css={tw`w-full justify-start`}
        text={() => (
          <span>
            Don't forget to click on the <strong>Save Changes</strong> button to
            save your changes.
          </span>
        )}
      />

      <ProfileForm onSubmit={handleUpdate}>
        <div css={tw`my-3 flex items-center gap-4 float-right`}>
          {user?.accountCode ? (
            <div>
              Account Code: <strong>{user.accountCode}</strong>
            </div>
          ) : null}
          <Button
            useSubmitBehavior
            text='Save Changes'
            type='default'
            icon='pi pi-sign-in'
            css={tw`!rounded-full`}
          />
        </div>
        <Form
          formData={formData}
          labelLocation='top'
          labelMode='floating'
          onFieldDataChanged={onFieldDataChanged}
          showRequiredMark={false}
          ref={formRef}
        >
          <GroupItem caption='Personal Details' colCount={3}>
            <SimpleItem render={profileImageRender} />
            <GroupItem colSpan={2}>
              <GroupItem colCount={2}>
                <SimpleItem dataField='firstName'>
                  <RequiredRule />
                  <PatternRule
                    message='Do not use digits in the Name'
                    pattern={/^[^0-9]+$/}
                  />
                </SimpleItem>
                <SimpleItem dataField='lastName'>
                  <RequiredRule />
                  <PatternRule
                    message='Do not use digits in the Name'
                    pattern={/^[^0-9]+$/}
                  />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField='email'
                  editorOptions={{
                    mode: "email",
                  }}
                  helpText='Please add a valid email. Job application status and payment receipts will be sent to it.'
                >
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem
                  dataField='phone'
                  helpText='Please add a valid phone number.'
                  editorOptions={{ mode: "tel" }}
                  // render={renderNumberInput}
                  // label={{ text: "Phone", location: "top" }}
                >
                  <RequiredRule />
                </SimpleItem>
              </GroupItem>
              <GroupItem colCount={2}>
                <SimpleItem
                  dataField='education'
                  editorType='dxSelectBox'
                  label={{ text: "Education level" }}
                  editorOptions={{
                    dataSource: educationLevelData,
                    displayExpr: "display",
                    valueExpr: "value",
                    searchEnabled: false,
                    showClearButton: true,
                  }}
                />
                {formData.education === "other" ? (
                  <SimpleItem
                    dataField='educationOther'
                    label={{ text: "Please specify" }}
                  >
                    <RequiredRule />
                  </SimpleItem>
                ) : null}
              </GroupItem>
            </GroupItem>
          </GroupItem>
          <GroupItem caption='Address' colCount={3}>
            <SimpleItem label={{ text: "Street" }} dataField='address.street'>
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              label={{ text: "House Number" }}
              dataField='address.houseNumber'
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='address.apartNumber'
              label={{ text: "Unit/Apartment" }}
            />
            <SimpleItem label={{ text: "City" }} dataField='address.city'>
              <RequiredRule />
              <PatternRule
                pattern={/^[^0-9]+$/}
                message='Do not use digits in the City name'
              />
            </SimpleItem>
            <SimpleItem
              editorType='dxSelectBox'
              dataField='address.provience'
              label={{ text: "Province" }}
              editorOptions={{
                dataSource: provinceArray,
                searchEnabled: false,
                showClearButton: true,
              }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              label={{ text: "Postal Code" }}
              dataField='address.postalCode'
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='address.country'
              label={{ text: "Country" }}
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: countryoOptions,
                displayExpr: "label",
                valueExpr: "label",
                searchEnabled: true,
                showClearButton: false,
              }}
            >
              <RequiredRule />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption='Work Details' colCount={3}>
            <SimpleItem
              dataField='jobPrefrence'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: jobPrefrenceData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: false,
                showClearButton: false,
              }}
            />
            <SimpleItem
              dataField='employmentStatus'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: employmentStatusData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: true,
                showClearButton: true,
              }}
            />
            {formData.employmentStatus === "other" ? (
              <SimpleItem
                dataField='employementOther'
                label={{ text: "Please specify" }}
              >
                <RequiredRule />
              </SimpleItem>
            ) : null}
          </GroupItem>
          <GroupItem caption='Residence Details' colCount={3}>
            <SimpleItem
              dataField='residenceStatus'
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: residenceStatusData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: true,
                showClearButton: false,
              }}
            >
              <RequiredRule />
            </SimpleItem>
            {formData.residenceStatus === "other" ? (
              <SimpleItem
                dataField='residenceOther'
                label={{ text: "Please specify" }}
              >
                <RequiredRule />
              </SimpleItem>
            ) : null}
            <SimpleItem
              dataField='sinNumber'
              editorType='dxNumberBox'
              editorOptions={{
                inputAttr: {
                  maxLength: 9,
                },
              }}
            >
              <RequiredRule />
              <NumericRule ignoreEmptyValue message='Must be a number' />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption='Payment Details'>
            <GroupItem colCount={3}>
              <SimpleItem
                dataField='paymentType'
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: paymentTypeData,
                  displayExpr: "display",
                  valueExpr: "value",
                  searchEnabled: true,
                  showClearButton: false,
                }}
              >
                <RequiredRule />
              </SimpleItem>

              {formData.paymentType === "eTransfer" ? (
                <SimpleItem
                  dataField='eTransferEmail'
                  helpText='Please add a valid eTransfer email. This will be used for payment'
                >
                  <RequiredRule />
                </SimpleItem>
              ) : null}
            </GroupItem>
          </GroupItem>
          <GroupItem>
            {formData.paymentType === "directDeposit" ? (
              <GroupItem caption='Bank Details' colCount={3}>
                <SimpleItem
                  dataField='bankDetails.accountNumber'
                  editorType='dxNumberBox'
                >
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem
                  dataField='bankDetails.accountType'
                  editorType='dxSelectBox'
                  editorOptions={{
                    dataSource: accountTypeData,
                    displayExpr: "display",
                    valueExpr: "value",
                    searchEnabled: false,
                    showClearButton: false,
                  }}
                >
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem dataField='bankDetails.institutionNumber'>
                  <RequiredRule />
                </SimpleItem>
                <SimpleItem dataField='bankDetails.transitNumber'>
                  <RequiredRule />
                </SimpleItem>
              </GroupItem>
            ) : null}
          </GroupItem>
          <GroupItem caption='Emergency Contact' colCount={3}>
            <SimpleItem
              dataField='emergencyContact.name'
              label={{ text: "Name" }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.relationship'
              label={{ text: "Relationship" }}
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: relationshipData,
                displayExpr: "display",
                valueExpr: "value",
                searchEnabled: true,
                showClearButton: false,
              }}
            >
              <RequiredRule />
            </SimpleItem>
            {formData.emergencyContact.relationship === "other" ? (
              <SimpleItem
                dataField='relationshipOther'
                label={{ text: "Please specify" }}
              >
                <RequiredRule />
              </SimpleItem>
            ) : null}
            <SimpleItem
              dataField='emergencyContact.phone'
              helpText='Please add a valid phone number.'
              editorOptions={{ mode: "tel" }}
              label={{ text: "Phone" }}
              // render={renderEmerNumberInput}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.email'
              label={{ text: "Email" }}
              editorOptions={{ mode: "email" }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.address.street'
              label={{ text: "Street" }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.address.houseNumber'
              label={{ text: "House Number" }}
              editorType='dxNumberBox'
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='apartNumber'
              label={{ text: "Unit/Apartment" }}
            />
            <SimpleItem
              dataField='emergencyContact.address.city'
              label={{ text: "City" }}
            >
              <RequiredRule />
              <PatternRule
                pattern={/^[^0-9]+$/}
                message='Do not use digits in the City name'
              />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.address.province'
              label={{ text: "Province" }}
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: provinceArray,
                searchEnabled: false,
                showClearButton: true,
              }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.address.postalCode'
              label={{ text: "Postal Code" }}
            >
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField='emergencyContact.address.country'
              label={{ text: "Country" }}
              editorType='dxSelectBox'
              editorOptions={{
                dataSource: countryoOptions,
                displayExpr: "label",
                valueExpr: "label",
                searchEnabled: true,
                showClearButton: false,
              }}
            >
              <RequiredRule />
            </SimpleItem>
          </GroupItem>
          <GroupItem caption='Documents'>
            <SimpleItem
              render={() => (
                // <Alert>
                //   <span>
                //     Please upload at least one valid means of identification
                //     from the drop down below. This is needed for your profile
                //     verification. You can also add as many documents you want
                //     even your resume for application.
                //   </span>
                // </Alert>
                <Message
                  severity='info'
                  tw='w-full justify-start'
                  text={() => (
                    <span>
                      Please upload at least one valid means of identification
                      from the drop down below. This is needed for your profile
                      verification. You can also add as many documents you want
                      even your resume for application.
                    </span>
                  )}
                />
              )}
            />
            <GroupItem colCount={3}>
              <SimpleItem
                dataField='documentType'
                editorType='dxSelectBox'
                helpText='Select document type you wish to upload'
                editorOptions={{
                  dataSource: documentTypeData,
                  searchEnabled: true,
                  showClearButton: false,
                }}
              />
              {formData.documentType === "Other" ? (
                <SimpleItem
                  dataField='docTypeOther'
                  label={{ text: "Please specify" }}
                >
                  <RequiredRule />
                </SimpleItem>
              ) : null}
            </GroupItem>
            <SimpleItem render={renderDocUpload} />
          </GroupItem>
          <GroupItem caption='Uploaded Documents'>
            <SimpleItem
              render={() => <ProfileDocData idDocURLS={idDocURLS} />}
            />
          </GroupItem>
          {/* <ButtonItem
            horizontalAlignment='right'
            buttonOptions={buttonOptions}
            tw='rounded-full!'
          /> */}
        </Form>
        <div css={tw`my-3 float-right`}>
          <Button
            useSubmitBehavior
            text='Save Changes'
            type='default'
            icon='pi pi-sign-in'
            css={tw`!rounded-full`}
          />
        </div>
      </ProfileForm>
    </div>
  );
};

export default ProfileSettings;
