import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "react-dropzone-uploader/dist/styles.css";
import "primeicons/primeicons.css";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "flag-icon-css/css/flag-icons.min.css";
import "styles/css/dx.material.blue.light.css";
import "react-phone-input-2/lib/material.css";
import "react-notifications-component/dist/theme.css";

import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Switch, Route, Redirect } from "react-router-dom";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";

/* Inner Pages */
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import BlogIndexPage from "pages/BlogIndex.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ThankYouPage from "ThankYouPage.js";
import Profile from "pages/Profile";

import AuthUser from "pages/AuthUser";
import JobsPage from "pages/JobsPage";
import JobDetailsPage from "pages/JobDetailsPage";
import BlogDetail from "pages/BlogDetail";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <Switch>
      <Route path='/profile' component={Profile} />

      <Route exact path='/thank-you'>
        <ThankYouPage />
      </Route>
      <Route exact path='/login'>
        <LoginPage />
      </Route>
      <Route exact path='/sign-up'>
        <SignupPage />
      </Route>
      <Route exact path='/about'>
        <AboutUsPage />
      </Route>
      <Route exact path='/tasks'>
        <JobsPage />
      </Route>
      <Route exact path='/task/:id'>
        <JobDetailsPage />
      </Route>
      <Route exact path='/contact'>
        <ContactUsPage />
      </Route>
      <Route exact path='/blog'>
        <BlogIndexPage />
      </Route>
      <Route exact path='/post/:slug'>
        <BlogDetail />
      </Route>
      <Route exact path='/terms-of-service'>
        <TermsOfServicePage />
      </Route>
      <Route exact path='/privacy-policy'>
        <PrivacyPolicyPage />
      </Route>
      <Route exact path='/auth'>
        <AuthUser />
      </Route>

      <Route exact path='/forgot-password'>
        <ForgotPassword />
      </Route>

      <Route exact path='/password-reset'>
        <ResetPassword />
      </Route>

      <Route exact path='/'>
        <SaaSProductLandingPage />
      </Route>
      <Redirect from='*' to='/' />
    </Switch>
  );
}

/* Todo
- Add payment email withs
- 

*/
