import "styled-components/macro";
import React from "react";
import { Link } from "react-router-dom";
// import useCategories from "hooks/useCategories";
import tw from "twin.macro";

const Header = () => {
  //   const { data } = useCategories();

  return (
    <div css={tw`container mx-auto px-10 mb-8`}>
      <div css={tw`border-b w-full inline-block border-blue-400 py-8`}>
        <div css={tw`md:float-left block`}>
          <Link to='/blog'>
            <span css={tw`cursor-pointer font-bold text-4xl text-white`}>
              Blog Posts
            </span>
          </Link>
        </div>
        {/* <div tw='hidden md:float-left md:contents'>
          {data?.map((category, index) => (
            <Link key={index} to={`/category/${category.slug}`}>
              <span tw='md:float-right mt-2 align-middle text-white ml-4 font-semibold cursor-pointer'>
                {category.name}
              </span>
            </Link>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Header;
