import { GRAPH_ENDPOINT } from "constant";
import { request, gql } from "graphql-request";
import { useQuery } from "react-query";

const usePostDetails = (slug) => {
  const query = gql`
    query GetPostDetails($slug: String!) {
      post(where: { slug: $slug }) {
        title
        excerpt
        featuredImage {
          url
        }
        author {
          name
          bio
          photo {
            url
          }
        }
        createdAt
        slug
        content {
          raw
        }
        categories {
          name
          slug
        }
      }
    }
  `;

  return useQuery(["postDetails", slug], async () => {
    const result = await request(GRAPH_ENDPOINT, query, { slug });
    return result.post;
  });
};

export default usePostDetails;
