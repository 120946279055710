export const educationLevelData = [
  {
    value: "primary",
    display: "Primary education",
  },
  {
    value: "secondary",
    display: "Secondary education/college",
  },
  {
    value: "higher",
    display: "Higher education",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const jobPrefrenceData = [
  {
    value: "casual",
    display: "Casual",
  },
  {
    value: "partTime",
    display: "Part time",
  },
  {
    value: "fullTime",
    display: "Full time",
  },
  {
    value: "openToAny",
    display: "Open to any",
  },
];

export const employmentStatusData = [
  {
    value: "unemployed",
    display: "Unemployed",
  },
  {
    value: "retired",
    display: "Retired",
  },
  {
    value: "student",
    display: "Student",
  },
  {
    value: "partTimeEmployed",
    display: "Part - time employed",
  },
  {
    value: "selfEmployed",
    display: "Self-employed/business owner",
  },
  {
    value: "other",
    display: "Other",
  },
];

// student visa, work permit, PR, Canadian citizen, others

export const residenceStatusData = [
  {
    value: "studentVisa",
    display: "Student visa",
  },
  {
    value: "workPermit",
    display: "Work permit",
  },
  {
    value: "pr",
    display: "Permanent resident",
  },
  {
    value: "citizen",
    display: "Canadian citizen",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const payOutTypeData = [
  //   {
  //     value: "daily",
  //     display: "Daily",
  //   },
  {
    value: "weekly",
    display: "Weekly",
  },
  {
    value: "bi-weekly",
    display: "Bi weekly",
  },
  {
    value: "monthly",
    display: "Monthly",
  },
];

export const paymentTypeData = [
  {
    value: "directDeposit",
    display: "Direct deposit",
  },
  {
    value: "eTransfer",
    display: "E-transfer",
  },
  //   {
  //     value: "cash",
  //     display: "Cash (coming soon)",
  //   },
];

export const accountTypeData = [
  {
    value: "checking",
    display: "Checking",
  },
  {
    value: "savings",
    display: "Savings",
  },
];

export const relationshipData = [
  {
    value: "spouse",
    display: "Spouse",
  },
  {
    value: "mother",
    display: "Mother",
  },
  {
    value: "father",
    display: "Father",
  },
  {
    value: "brother",
    display: "Brother",
  },
  {
    value: "sister",
    display: "Sister",
  },
  {
    value: "other",
    display: "Other",
  },
];

export const documentTypeData = [
  "Drivers' license",
  "Student visa",
  "Work permit",
  "PR card",
  "Bio data page of international passport",
  "Resume",
  "Other",
];

export const userProfileFields = [
  "firstName",
  "lastName",
  "address",
  "phone",
  "email",
  "jobPrefrence",
  "workPrefrence",
  "employmentStatus",
  "lang",
  "residenceStatus",
  "paymentType",
  "payOutType",
  "education",
  "sinNumber",
  "bankDetails",
  "idDocURLS",
  "resume",
  "emergencyContact",
  "eTransferEmail",
];

export const appliedJobStatus = [
  {
    value: "in-review",
    display: "In Review",
  },
  {
    value: "accepted",
    display: "Accepted",
  },
  {
    value: "rejected",
    display: "Rejected",
  },
];

export const provinceArray = [
  "Newfoundland and Labrador",
  "Prince Edward Island",
  "Nova Scotia",
  "New Brunswick",
  "Quebec",
  "Ontario",
  "Manitoba",
  "Saskatchewan",
  "Alberta",
  "British Columbia",
  "Yukon",
  "Northwest Territories",
  "Nunavut",
];
