import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import LogoImage from "images/task_logo.png";
// import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { Link } from "react-router-dom";
import { useFormspark } from "@formspark/use-formspark";
import { showNotification } from "utils/notification";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const StyledLink = tw(
  Link
)`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(
  Column
)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded-full sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(
  PrimaryButtonBase
)`mt-4 sm:mt-0 w-full sm:w-auto rounded-full sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;
// const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

// const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4`}
//   }
// `;

export default () => {
  const [email, setEmail] = useState("");

  const [submit, submitting] = useFormspark({
    formId: process.env.REACT_APP_NEWSLETTER_FORM_ID,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;
    try {
      await submit({ email, subject: "Newsletter" });
      showNotification(
        "Thank you for subscribing to our newsletter. You're now part of our community!",
        "success"
      );
      setEmail("");
    } catch (error) {
      showNotification("An error occurred. Please try again!", "error");
    }
  };

  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Info</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink to='/blog'>Blog</StyledLink>
              </LinkListItem>
              {/* <LinkListItem>
                <StyledLink to='/faqs'>FAQs</StyledLink>
              </LinkListItem> */}
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink to='/tasks'>Tasks</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink to='/contact'>Support</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink to='/about'>About Us</StyledLink>
              </LinkListItem>
            </LinkList>
          </Column>
          {/* <Column>
            <ColumnHeading>Press</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link to="#">Logos</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Events</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Stories</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Office</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <StyledLink to='/privacy-policy'>Privacy Policy</StyledLink>
              </LinkListItem>
              <LinkListItem>
                <StyledLink to='/terms-of-service'>Terms of Service</StyledLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                Stay upto date with latest features and updates.
              </SubscribeText>
              <SubscribeForm onSubmit={onSubmit}>
                <Input
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Your Email Address'
                  required
                />
                <SubscribeButton disabled={submitting} type='submit'>
                  Subscribe
                </SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            {/* <LogoText>Treact Inc.</LogoText> */}
          </LogoContainer>
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} taskpip. All Rights Reserved.
          </CopywrightNotice>
          {/* <SocialLinksContainer>
            <SocialLink href='https://facebook.com'>
              <FacebookIcon />
            </SocialLink>
            <SocialLink href='https://twitter.com'>
              <TwitterIcon />
            </SocialLink>
            <SocialLink href='https://youtube.com'>
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </ThreeColRow>
      </Content>
    </Container>
  );
};
