import "styled-components/macro";
import { useState } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Editing,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { TabContent } from "components/cards/TabCardGrid";
import tw from "twin.macro";
import { Button } from "devextreme-react";
// import Alert from "components/misc/Alert";
import { useAuth } from "context/auth";
import TaskDetails from "./TaskDetails";
import { Message } from "primereact/message";
import JobService from "service/jobService";
import { showNotification } from "utils/notification";

// amountPaid: number;
//   dateOfPayment: string;
//   totalHours: number;

const PaidTasks = () => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user, getUser } = useAuth();

  const closeDetailsModal = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const onRowRemoving = async ({ key }) => {
    try {
      await JobService.deleteJob(key, "appliedJobs");
      showNotification("Successful", "success");
      getUser(true);
    } catch (error) {
      showNotification(error, "error");
      getUser(true);
    }
  };

  const renderViewJobInfo = ({ data }) => {
    return (
      <Button
        type='default'
        stylingMode='outlined'
        text='View Job Details'
        css={tw`!rounded-full`}
        onClick={() => {
          setSelected(data);
          setIsOpen(true);
        }}
      />
    );
  };

  const format = {
    precision: 2,
    type: "fixedPoint",
  };

  return (
    <TabContent css={tw`sm:mr-0! md:mr-0! lg:mr-0!`}>
      <Message
        severity='info'
        css={tw`w-full justify-start mb-8`}
        text={() => (
          <span>
            All amount/price are in canadian dollars (C$).{" "}
            <strong>NOTE:</strong> Hours used for payment is hours added by
            employer.
          </span>
        )}
      />
      {/* <Alert>
        <span>
          All amount/price are in canadian dollars (C$). <br />{" "}
          <strong>NOTE:</strong> Hours used for payment is hours added by
          employer.
        </span>
      </Alert> */}
      <DataGrid
        dataSource={user?.profile?.jobs?.paidJobs || []}
        keyExpr='jobCode'
        showBorders
        wordWrapEnabled
        columnAutoWidth={false}
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        onRowRemoving={onRowRemoving}
      >
        <Editing
          allowDeleting={true}
          allowAdding={false}
          allowUpdating={false}
          mode='row'
          // useIcons={true}
        />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' caption='Task Code' />
        <Column dataField='title' />
        <Column dataField='jobPosterName' caption='Organization' />
        <Column
          dataField='priceByHour'
          format={{ precision: 2, type: "currency" }}
        />
        <Column
          dataField='totalHours'
          caption='Total hours (added by me)'
          visible={false}
        />
        <Column
          dataField='attendanceByEmployer.totalHours'
          caption='Total hours (added by employer)'
          visible={false}
        />
        <Column
          dataField='amountPaid'
          format={{ precision: 2, type: "currency" }}
        />
        <Column dataField='dateOfPayment' dataType='date' />
        <Column
          cellRender={renderViewJobInfo}
          alignment='center'
          showInColumnChooser={false}
          caption='Job Details'
        />
        <Summary>
          <TotalItem
            column='totalHours'
            displayFormat='Total Hours: {0}'
            summaryType='sum'
          />
          <TotalItem
            column='amountPaid'
            summaryType='sum'
            displayFormat='Total Amount: {0}'
            valueFormat={format}
          />
        </Summary>
      </DataGrid>
      {selected && (
        <TaskDetails
          visible={isOpen}
          selected={selected}
          onHide={closeDetailsModal}
          paid
        />
      )}
    </TabContent>
  );
};

export default PaidTasks;
