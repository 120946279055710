import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "context/auth";
import { AppGlobalStateProvider } from "context/appGlobalState";
import ScrollToTop from "components/misc/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactNotifications } from "react-notifications-component";

const Provider = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 7 * 1000,
        // refetchOnReconnect: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        // refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <Router>
      <ReactNotifications isMobile />
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthProvider>
          <AppGlobalStateProvider>{children}</AppGlobalStateProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default Provider;
