import "styled-components/macro";
import React from "react";
import { PrimeIcons } from "primereact/api";
import UserInfo from "components/user-info/UserInfo";
import tw from "twin.macro";

const ProfileHeader = ({ toggleHeader, setToggleHeader }) => {
  return (
    <React.Fragment>
      <header
        className={toggleHeader ? "header body-pd" : "header"}
        id='header'
        css={tw`bg-white border-b`}
      >
        <div
          className='header__toggle'
          onClick={() => setToggleHeader(!toggleHeader)}
        >
          <i
            className={!toggleHeader ? PrimeIcons.BARS : PrimeIcons.TIMES}
            id='header-toggle'
          ></i>
        </div>
        {/*
      <div className="header__img">
        <img
          src={user?.profilePicUrl ? user.profilePicUrl : avatar}
          alt="Img"
        />
      </div> */}
        <div css={tw`mr-5`}>
          <UserInfo />
        </div>
      </header>
    </React.Fragment>
  );
};

export default ProfileHeader;
