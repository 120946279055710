import "styled-components/macro";
import React from "react";
// import Categories from "./Categories";
import PostCard from "./PostCard";
import PostWidget from "./PostWidget";
import tw from "twin.macro";
import usePosts from "hooks/usePosts";
import FeaturedPosts from "./FeaturedPosts";
import { LoadPanel } from "devextreme-react";

const Main = () => {
  const { data, isLoading, isError } = usePosts();

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    return null;
  }

  return (
    <div css={tw`container mx-auto mb-8`}>
      <FeaturedPosts />
      <div css={tw`grid grid-cols-1 lg:grid-cols-12 gap-12`}>
        <div css={tw`lg:col-span-8 col-span-1`}>
          {data?.map((post, index) => (
            <PostCard key={index} post={post.node} />
          ))}
        </div>
        <div css={tw`lg:col-span-4 col-span-1`}>
          <div css={tw`lg:sticky relative`}>
            <PostWidget />
            {/* <Categories /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
