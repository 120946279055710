import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/TwoColWithSteps";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
import AboutImage from "images/about.svg";
import VisionImage from "images/vision.svg";
import ValuesImage from "images/values.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header roundedHeaderButton />
      <MainFeature1
        subheading={<Subheading>About taskpip</Subheading>}
        heading='We are not just another Start-Up in the eco-system.'
        description={
          <span>
            At taskpip we are revolutionising the ways people get income while
            assisting organisations in increasing profitability by matching
            their activity level with the required manpower. <br /> <br />
            We give workers the power to chose where and when to work.
          </span>
        }
        // buttonRounded
        // primaryButtonText="See Portfolio"
        imageSrc={AboutImage}
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading='Enabling a healthy work structure'
        description={`Our passion which created the vision is to create a healthy work
          environment where people are happy doing what they do and motivated
          to give maximum effort in every task.`}
        // buttonRounded
        // primaryButtonText="Contact Us"
        imageSrc={VisionImage}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading='We follow these'
        imageSrc={ValuesImage}
        steps={[
          {
            heading: "24/7 Support",
            description: (
              <span>
                We are available 7days a Week and 24 hours a Day. <br />
                Our supports staffs are trained not just to resolve your queries
                but to give you an overall excellent experience. Feel free to
                contact US.
              </span>
            ),
          },
          {
            heading: "Freedom To Work  ",
            description: `The pandemic has definitely changed our Lifes and manners
              in which people want to work. There has been a huge call
              for more work flexibility to enables workers attend to
              more personal issues and dreams. This has led to a massive
              resignation across several industries in the world and left Manager,
              business owners and even the government asking one question.
              “Why are people quitting jobs”. The answer is not far-fetched,
              flexibility, ability to choose and control are words constantly
              been said by potential employees. `,
          },
        ]}
        linkText=''
      />
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
