import "styled-components/macro";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
} from "devextreme-react/data-grid";
import { TabContent } from "components/cards/TabCardGrid";
import { Button } from "devextreme-react";
import { useAuth } from "context/auth";
import { useState } from "react";
import TaskDetails from "./TaskDetails";
import Attendance from "./Attendance";
import { useMutation } from "react-query";
import JobService from "service/jobService";
import { confirm } from "devextreme/ui/dialog";
import tw from "twin.macro";
import { showNotification } from "utils/notification";
import { Message } from "primereact/message";

const InProgress = ({ defaultPageSize, allowedPageSizes }) => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAttendanceModalOpen, setisAttendanceModalOpen] = useState(false);
  const [attendance, setAttendance] = useState(null);

  const { user, getUser } = useAuth();

  const completeJob = useMutation((jobId) => JobService.completeJob(jobId));

  const closeDetailsModal = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const closeAttendanceModal = () => {
    setAttendance(null);
    setisAttendanceModalOpen(false);
  };

  const handleCompeteJob = async (jobId, totalHours, attendanceByEmployer) => {
    const message = `
    <div>
      <h1> <strong> Total Hours Worked: </strong> ${totalHours} </h2>
      <h1> <strong> Total Hours Added by Employer: </strong> ${attendanceByEmployer} </h2>
      <p> Are you sure you want to complete this job ? <br /> Make sure attendance have been completed and hours confirmed </p>
    </div>
    
    `;
    const result = await confirm(message, "Confirmation");

    if (result) {
      await completeJob.mutateAsync(jobId, {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSuccess: () => {
          showNotification("Successful", "success");
          getUser();
        },
      });
    }
  };

  const cellRender = ({ data }) => {
    return (
      <div
        css={[
          tw`flex sm:flex-row flex-col justify-center items-center sm:space-x-2 space-x-0 sm:space-y-0 space-y-2`,
        ]}
      >
        <Button
          type='default'
          //   stylingMode='outlined'
          text='Add Attendance'
          css={tw`!rounded-full`}
          onClick={() => {
            setAttendance(data);
            setisAttendanceModalOpen(true);
          }}
        />
        <Button
          type='success'
          //   stylingMode='outlined'
          text='Confirm Job Completed'
          css={tw`!rounded-full bg-green-600! hover:bg-green-700!`}
          onClick={() =>
            handleCompeteJob(
              data.jobId,
              data.totalHours,
              data?.attendanceByEmployer?.totalHours
            )
          }
        />
      </div>
    );
  };

  const renderViewJobInfo = ({ data }) => {
    return (
      <Button
        type='default'
        stylingMode='outlined'
        text='View Job Details'
        css={tw`!rounded-full`}
        onClick={() => {
          setSelected(data);
          setIsOpen(true);
        }}
      />
    );
  };

  return (
    <TabContent css={[tw`sm:mr-0! md:mr-0! lg:mr-0!`]}>
      <Message
        severity='info'
        css={tw`w-full justify-start mb-8`}
        text={() => (
          <span>
            Don't forget to add task attendance by clicking the{" "}
            <strong>Add Attendance</strong> button.
          </span>
        )}
      />
      <DataGrid
        dataSource={user?.profile?.jobs?.inProgressJobs || []}
        keyExpr='jobId'
        showBorders
        wordWrapEnabled
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={defaultPageSize || 5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes || [5, 10, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' caption='Task Code' />
        <Column dataField='title' />
        <Column dataField='jobPosterName' caption='Organization' />
        <Column caption='Action' cellRender={cellRender} alignment='center' />
        <Column
          cellRender={renderViewJobInfo}
          alignment='center'
          showInColumnChooser={false}
          caption='Job Details'
        />
      </DataGrid>
      {selected && (
        <TaskDetails
          visible={isOpen}
          selected={selected}
          onHide={closeDetailsModal}
        />
      )}
      {attendance && (
        <Attendance
          visible={isAttendanceModalOpen}
          jobData={attendance}
          onHide={closeAttendanceModal}
        />
      )}
    </TabContent>
  );
};

export default InProgress;
