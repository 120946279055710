import "styled-components/macro";
import React, { useState, useRef, useCallback } from "react";
import Image from "components/misc/Image";
import { Button } from "devextreme-react";
import avatar_img from "images/avatar.png";
import verified from "images/verified.svg";
import { useAuth } from "context/auth";
import { useHistory, useLocation } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import tw from "twin.macro";

const UserInfo = () => {
  const [isVisible, setVisible] = useState(false);

  const { user, logout } = useAuth();

  const { push } = useHistory();
  const { pathname } = useLocation();

  const ref = useRef();

  const hanndler = useCallback(() => {
    setVisible(false);
  }, []);

  useOnClickOutside(ref, hanndler);

  return (
    <div
      css={[
        tw`flex justify-center items-center cursor-pointer space-x-1 lg:mt-0 mt-3 w-auto relative`,
      ]}
      onClick={() => setVisible((prev) => !prev)}
      id='userInfo'
      ref={ref}
    >
      <div className='circular--portrait circular-sm '>
        <Image
          src={user?.profilePicUrl || avatar_img}
          css={[tw`cursor-pointer`]}
        />
      </div>

      <span tw='text-sm'>{user?.username}</span>
      {user?.profile && user?.profile.profileStatus === "active" && (
        <Image
          src={verified}
          width={13}
          height={13}
          style={{ marginTop: "4px" }}
        />
      )}

      {isVisible ? (
        <div
          css={[
            tw`absolute border border-gray-200 rounded-lg shadow-lg
          h-auto bg-white w-40 cursor-pointer z-10 flex items-center
          justify-center flex-col`,
          ]}
          style={{ top: "31px" }}
        >
          {pathname.includes("/profile") ? (
            <Button
              text='Profile Settings'
              hint='Profile Settings'
              stylingMode='text'
              icon='pi pi-user-edit'
              css={[tw`w-full`]}
              onClick={() => push("/profile/settings")}
            />
          ) : (
            <>
              <Button
                text='Dashboard'
                hint='Dashboard'
                stylingMode='text'
                icon='pi pi-th-large'
                css={[tw`w-full`]}
                onClick={() => push("/profile")}
              />
              <Button
                text='Profile'
                hint='Profile'
                stylingMode='text'
                icon='pi pi-user'
                css={[tw`w-full`]}
                onClick={() => push("/profile/settings")}
              />
            </>
          )}

          <Button
            text='Logout'
            hint='Logout'
            stylingMode='text'
            icon='pi pi-sign-out'
            css={[tw`w-full`]}
            onClick={logout}
          />
        </div>
      ) : null}
    </div>
  );
};

export default UserInfo;
