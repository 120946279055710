import "styled-components/macro";
import React, { useState } from "react";
import { Button, ScrollView, TagBox } from "devextreme-react";
import { ToolbarItem, Popup } from "devextreme-react/popup";
import RichTextEditor from "react-rte";
import Dropzone from "react-dropzone-uploader";
import { useAppGlobalState } from "context/appGlobalState";
import { Badge } from "components/misc/Badge";
import { API_URL } from "constant";
import { getToken } from "utils";
import { showNotification } from "utils/notification";
import ProfileService from "service/profileService";
import { alert } from "devextreme/ui/dialog";
import { useAuth } from "context/auth";
import { useMutation, useQueryClient } from "react-query";
import JobService, { jobServiceQueryKeys } from "service/jobService";

import tw from "twin.macro";

const intersections = (array1, array2) =>
  array1.filter((e) => array2.indexOf(e.id) !== -1);

const JobApplication = ({ visible, onHide, jobDetails }) => {
  const [coverLetter, setCoverLetter] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [applicationDays, setApplicationDays] = useState([]);
  const [docus, setDocus] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);

  const { idDocURLS } = useAppGlobalState();
  const { getUser } = useAuth();
  const client = useQueryClient();
  const applyToJob = useMutation((requestData) => {
    return JobService.applyToJob(
      requestData.applicationData,
      requestData.jobId
    );
  });

  //   console.log(coverLetter.toString("html"));

  //   console.log("idDocURLS", idDocURLS);

  const getUploadParams = async ({ file }) => {
    const body = new FormData();

    body.append("file", file);
    body.append("type", "Application Document");
    body.append("fileName", file.name);

    return {
      url: `${API_URL}/api/v1/user/profile/file`,
      headers: { token: getToken() },
      body,
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove, xhr }, status) => {
    if (status === "error_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "exception_upload") {
      showNotification(
        meta ? `${meta.name}, upload failed...` : "Error uploading file",
        "error"
      );
    }

    if (status === "aborted") {
      showNotification(`${meta.name}, upload failed...`, "error");
    }

    if (status === "done") {
      const { data } = JSON.parse(xhr.response);
      setUploadedDocs((pre) => [...pre, data]);
      showNotification(`${meta.name} uploaded successfully`, "success");
      remove();
    }
  };

  const removeDoc = async (id) => {
    const filtered = [...uploadedDocs].filter((docu) => docu.id !== id);
    setUploadedDocs(filtered);

    try {
      await ProfileService.deleteFile(id, true);
    } catch (error) {}
  };

  //   applicationDocs: IApplicationDoc[];
  //   coverLetter: string;
  //   applicationStatus: appliedJobStatus;
  //   appliedDays: ITaskDays[];

  const submitApplication = async () => {
    if (!applicationDays.length) {
      alert(
        "Please select job day(s) that is suitable for you.",
        "Notification"
      );
      return;
    }

    let applicationDocs = [];

    if (docus.length) {
      const doc = intersections(idDocURLS, docus);
      applicationDocs = doc.map((doc) => ({ type: doc.type, URL: doc.URL }));
    }

    if (uploadedDocs) {
      const doc = uploadedDocs.map((doc) => ({ type: doc.type, URL: doc.URL }));
      applicationDocs = [...applicationDocs, ...doc];
    }

    // type: string;
    //   URL: string;
    const requestBody = {
      coverLetter: coverLetter.toString("markdown"),
      appliedDays: intersections(jobDetails.taskDays, applicationDays),
      applicationDocs,
    };

    await applyToJob.mutateAsync(
      { applicationData: requestBody, jobId: jobDetails._id },
      {
        onError: (error) => {
          showNotification(error, "error");
        },
        onSuccess: () => {
          showNotification(
            "Application submitted successful. We will notify you if you are selected for this task",
            "success"
          );
          client.invalidateQueries(jobServiceQueryKeys.GET_JOB_DETAILS);
          getUser();
          onHide();
        },
      }
    );
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHide}
      //   title={`Application`}
      showTitle
      showCloseButton={true}
      fullScreen
    >
      <ScrollView height='100%'>
        <div css={tw`m-4`}>
          <div css={tw`mb-4 text-lg`}>
            <strong>Applying to:</strong> {jobDetails.title}
          </div>
          <div>
            <span css={tw`text-base mb-1 inline-block`}>
              <span css={tw`text-base text-gray-600`}>
                Select suitable days
              </span>{" "}
            </span>
            <TagBox
              dataSource={jobDetails.taskDays.map((day) => ({
                ...day,
                display: `${day.date}, ( ${day.startTime} to ${day.endTime} )`,
              }))}
              displayExpr='display'
              css={tw`mb-4`}
              showDropDownButton
              searchEnabled
              showSelectionControls
              maxDisplayedTags={5}
              valueExpr='id'
              value={applicationDays}
              onValueChanged={({ value }) => {
                setApplicationDays(value);
              }}
            />
          </div>
          <div>
            <span css={tw`text-base my-3 inline-block `}>
              <span css={tw`text-base text-gray-600`}>
                Cover Letter (Optional)
              </span>{" "}
            </span>
            <RichTextEditor
              onChange={(value) => setCoverLetter(value)}
              value={coverLetter}
              editorClassName='editor'
            />
          </div>

          <div>
            <span css={tw`text-base mb-3 mt-6 inline-block`}>
              <span css={tw`text-base text-gray-600`}>
                Application Document (Optional)
              </span>
            </span>
            <TagBox
              dataSource={idDocURLS}
              displayExpr='type'
              css={tw`mb-4`}
              showDropDownButton
              searchEnabled
              showSelectionControls
              maxDisplayedTags={5}
              valueExpr='id'
              placeholder='Select from your documents'
              value={docus}
              onValueChanged={({ value }) => setDocus(value)}
            />
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              canRemove={true}
              maxSizeBytes={10485760}
              multiple={false}
              maxFiles={1}
              inputContent='Click here to upload a new application document'
            />
            <div css={tw`flex flex-wrap space-x-2 mt-3`}>
              {uploadedDocs.map((doc) => (
                <Badge css={tw`bg-primary-500 text-white mb-2`} key={doc.id}>
                  <span>{doc.fileName}</span>
                  <i
                    css={tw`ml-2 cursor-pointer`}
                    className='pi pi-times-circle'
                    onClick={() => removeDoc(doc.id)}
                  ></i>
                </Badge>
              ))}
            </div>
          </div>
        </div>
      </ScrollView>
      <ToolbarItem
        location='after'
        render={() => {
          return (
            <Button
              type='default'
              text='Send Application'
              css={tw`!rounded-full`}
              onClick={submitApplication}
            />
          );
        }}
      />
    </Popup>
  );
};

export default JobApplication;
