import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/forgot-pass.svg";
import logo from "images/task_logo.png";
// import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "context/auth";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const HTMLForm = tw.form`mx-auto max-w-xs`;
// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
// const SubmitButton = styled.button`
//   ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
//   .icon {
//     ${tw`w-6 h-6 -ml-2`}
//   }
//   .text {
//     ${tw`ml-3`}
//   }
// `;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-gray-200 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Forgot password ?",
  submitButtonText = "Request Password reset",
  SubmitButtonIcon = SignUpIcon,
  signInUrl = "/login",
}) => {
  const [accDetails] = useState({
    email: "",
  });

  const { user, requestResetPassword } = useAuth();

  const { push } = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    if (user) {
      if (state?.from) {
        push(state.from);
      } else {
        push("/");
      }
    }
  }, [user, push, state]);

  const handleRequestPasswordReset = (e) => {
    e.preventDefault();
    requestResetPassword(accDetails.email);
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                {/* <DividerTextContainer>
                  <DividerText>Or Sign up with your username</DividerText>
                </DividerTextContainer> */}
                <HTMLForm onSubmit={handleRequestPasswordReset}>
                  <Form labelMode='floating' formData={accDetails}>
                    {/* <Input
                    type="text"
                    placeholder="Username"
                    value={accDetials.username}
                    onChange={onChange}
                    name="username"
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={accDetials.password}
                    onChange={onChange}
                    name="password"
                  /> */}
                    <GroupItem>
                      <SimpleItem
                        dataField='email'
                        helpText='Please enter the email address that you used to register, and we will send you a link to reset your password via Email.'
                        editorOptions={{ mode: "email" }}
                      >
                        <RequiredRule />
                      </SimpleItem>
                    </GroupItem>

                    <ButtonItem
                      itemType='button'
                      cssClass='btn-block'
                      horizontalAlignment='center'
                      buttonOptions={{
                        text: submitButtonText,
                        type: "default",
                        useSubmitBehavior: true,
                        icon: "pi pi-send",
                      }}
                    />
                    {/* <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton> */}
                  </Form>
                  <p tw='mt-8 text-sm text-gray-600 text-center'>
                    Already have an account?{" "}
                    <Link
                      to={
                        state?.from
                          ? { pathname: signInUrl, state: state.from }
                          : signInUrl
                      }
                      tw='border-b border-gray-500 border-dotted'
                    >
                      Sign In
                    </Link>
                  </p>
                </HTMLForm>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
