import { GRAPH_ENDPOINT } from "constant";
import { request, gql } from "graphql-request";
import { useQuery } from "react-query";

const usePosts = () => {
  const query = gql`
    query GetPosts {
      postsConnection {
        edges {
          cursor
          node {
            author {
              bio
              name
              id
              photo {
                url
              }
            }
            createdAt
            slug
            title
            excerpt
            featuredImage {
              url
            }
            categories {
              name
              slug
            }
          }
        }
      }
    }
  `;

  return useQuery("posts", async () => {
    const result = await request(GRAPH_ENDPOINT, query);
    return result.postsConnection.edges;
  });
};

export default usePosts;
