import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import JobDetails from "components/jobs/JobDetails";

const JobDetailsPage = () => {
  return (
    <AnimationRevealPage disabled>
      <Header roundedHeaderButton />
      <JobDetails />
      <Footer />
    </AnimationRevealPage>
  );
};

export default JobDetailsPage;
