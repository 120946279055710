import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "images/home-real.jpg";
// import CustomersLogoStripImage from "images/customers-logo-strip.png";
import Comp1 from "images/comp1.jpeg";
import Comp2 from "images/comp2.jpeg";
import Comp3 from "images/comp3.png";
import Comp4 from "images/comp4.jpg";
import { Button } from "devextreme-react";
import { useHistory } from "react-router-dom";
import { PARTNERS_URL } from "constant/index.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

// const Actions = styled.div`
//   ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
//   input {
//     ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

// lg:pr-16 xl:pr-32

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 opacity-75 rounded-full`}
  }
  div {
    ${tw`flex space-x-2`}
  }
`;

const ActionConatiner = tw.div`flex sm:space-x-3 sm:space-y-0 space-y-3  items-center mt-3 sm:flex-row flex-col`;

export default ({ roundedHeaderButton }) => {
  const { push } = useHistory();

  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              taskpip <span tw='text-primary-500'>for you.</span>
            </Heading>
            <Paragraph>
              taskpip connects workers seeking temporal work shifts to meet
              employers need for extra workforce. <br /> <br />
              Maybe you’re saving up for school or keeping flexible hours to
              spend more time with your family. It’s a great time to get on
              taskpip, connect with workers and employers, and earn a little
              extra cash. Plus, you can tell people you’ve got the best boss in
              town.
            </Paragraph>
            <ActionConatiner>
              <Button
                text='Looking for work'
                type='default'
                tw='!rounded-full'
                onClick={() => push("/tasks")}
              />
              <Button
                text='I need workers'
                type='default'
                stylingMode='outlined'
                tw='!rounded-full'
                onClick={() => window.open(PARTNERS_URL, "_blank")}
              />

              {/* <Button
                text='I need some help'
                type='normal'
                stylingMode='contained'
                tw='rounded-full bg-gray-700 text-white hover:text-gray-800'
              /> */}
            </ActionConatiner>
            {/* <Actions>
              <input type="text" placeholder="Your E-mail Address" />
              <button>Get Started</button>
            </Actions> */}
            <CustomersLogoStrip>
              <p>Our TRUSTED Partners</p>
              <div>
                {/* <img src={CustomersLogoStripImage} alt="Our Customers" /> */}
                <img src={Comp1} alt='Our Customers' width={70} height={70} />
                <img src={Comp2} alt='Our Customers' width={70} height={70} />
                <img src={Comp3} alt='Our Customers' width={70} height={70} />
                <img src={Comp4} alt='Our Customers' width={70} height={70} />
              </div>
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw='min-w-0 w-full max-w-lg xl:max-w-3xl'
                src={DesignIllustration}
                alt='Design Illustration'
                loading='eager'
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
