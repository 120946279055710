import "styled-components/macro";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
} from "devextreme-react/data-grid";
import { TabContent } from "components/cards/TabCardGrid";
import { Badge } from "components/misc/Badge";
import { Button } from "devextreme-react";
import { appliedJobStatus } from "constant/data";
import { useState } from "react";
import { useAuth } from "context/auth";
import TaskDetails from "./TaskDetails";
// import Alert from "components/misc/Alert";
import { useMutation } from "react-query";
import JobService from "service/jobService";
import { showNotification } from "utils/notification";
import { confirm } from "devextreme/ui/dialog";
import { Message } from "primereact/message";
import tw from "twin.macro";

const AcceptedTasks = () => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { user, getUser } = useAuth();

  const startJob = useMutation((requestData) =>
    JobService.startJob(requestData)
  );

  const closeDetailsModal = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const markJobStarted = async (jobId) => {
    const result = await confirm(
      "Are you sure you want to mark this task as started ?",
      "Confirm"
    );

    if (result) {
      await startJob.mutateAsync(
        { jobId },
        {
          onError: (error) => {
            showNotification(error, "error");
          },
          onSuccess: () => {
            showNotification("Successful", "success");
            getUser();
          },
        }
      );
    }
  };

  const renderStatus = () => {
    return <Badge css={tw`bg-primary-500 text-white`}>Accepted</Badge>;
  };

  const renderAction = ({ data }) => {
    if (!data.isJobStarted) {
      return (
        <Button
          type='default'
          text='Mark Task Started'
          hint='Mark Task Started'
          css={tw`!rounded-full`}
          onClick={() => markJobStarted(data.jobId)}
        />
      );
    }
    // <Badge tw='bg-green-600 text-white'>Task Started</Badge>;
    return null;
  };

  const renderViewJobInfo = ({ data }) => {
    return (
      <Button
        type='default'
        stylingMode='outlined'
        text='View Details'
        css={tw`!rounded-full`}
        onClick={() => {
          setSelected(data);
          setIsOpen(true);
        }}
      />
    );
  };

  return (
    <TabContent css={tw`sm:mr-0! md:mr-0! lg:mr-0!`}>
      {user?.profile?.jobs?.acceptedJobs?.length ? (
        <Message
          severity='info'
          css={tw`w-full justify-start mb-8`}
          text={() => (
            <span>
              Please remember to mark started task as started by clicking the{" "}
              <strong>Mark Task Started</strong> button to move it to
              in-progress.
            </span>
          )}
        />
      ) : null}

      {/* <h3 tw='text-lg'>Accepted Tasks</h3> */}
      <DataGrid
        dataSource={user?.profile?.jobs?.acceptedJobs || []}
        keyExpr='jobCode'
        showBorders
        wordWrapEnabled
        columnAutoWidth={false}
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' caption='Task Code' />
        <Column dataField='title' caption='Task Title' />
        <Column dataField='jobPosterName' caption='Organization' />
        <Column dataField='isJobStarted'>
          <Lookup
            displayExpr='display'
            valueExpr='value'
            dataSource={[
              { display: "Yes", value: true },
              { display: "No", value: false },
            ]}
          />
        </Column>
        <Column
          dataField='status'
          caption='Application Status'
          cellRender={renderStatus}
          alignment='center'
          allowHeaderFiltering={true}
          allowFiltering={false}
          visible={false}
        >
          <Lookup
            dataSource={appliedJobStatus}
            valueExpr='value'
            displayExpr='display'
          />
        </Column>
        <Column
          cellRender={renderViewJobInfo}
          alignment='center'
          showInColumnChooser={false}
          caption='Task Details'
        />
        <Column
          cellRender={renderAction}
          alignment='center'
          showInColumnChooser={false}
        />
      </DataGrid>
      {selected && (
        <TaskDetails
          visible={isOpen}
          selected={selected}
          onHide={closeDetailsModal}
        />
      )}
    </TabContent>
  );
};

export default AcceptedTasks;
