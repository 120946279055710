import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/auth6.svg";
import logo from "images/task_logo.png";
import googleIconImageSrc from "images/google-icon.png";
// import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "context/auth";
import { API_URL } from "constant";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  ButtonItem,
  PatternRule,
} from "devextreme-react/form";
import { Button } from "devextreme-react";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
// const SocialButton = styled.span`
//   ${tw`w-full cursor-pointer max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
//   .iconContainer {
//     ${tw`bg-white p-2 rounded-full`}
//   }
//   .icon {
//     ${tw`w-4`}
//   }
//   .text {
//     ${tw`ml-4`}
//   }
// `;

const DividerTextContainer = tw.div`my-6  text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2  inset-x-0 top-1/2 bg-transparent`;

const HTMLForm = tw.form`mx-auto max-w-xs`;
// const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
// const SubmitButton = styled.button`
//   ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
//   .icon {
//     ${tw`w-6 h-6 -ml-2`}
//   }
//   .text {
//     ${tw`ml-3`}
//   }
// `;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-gray-200 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Sign Up",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: `${API_URL}/api/v1/auth/google`,
    },
    // {
    //   iconImageSrc: twitterIconImageSrc,
    //   text: "Sign Up With Twitter",
    //   url: "https://twitter.com",
    // },
  ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "/terms-of-service",
  privacyPolicyUrl = "/privacy-policy",
  signInUrl = "/login",
}) => {
  const [accDetials] = useState({
    username: "",
    password: "",
    email: "",
  });

  const { createUser, user } = useAuth();

  const { push } = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    if (user) {
      if (state?.from) {
        push(state.from);
      } else {
        push("/");
      }
    }
  }, [user, push, state]);

  const handleRegister = (e) => {
    e.preventDefault();
    createUser(accDetials);
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        window.open(socialButton.url, "_self");
                      }}
                      text={socialButton.text}
                      icon={socialButton.iconImageSrc}
                      tw='w-80 !rounded-full'
                    >
                      {/* <span className='iconContainer'>
                        <img
                          src={socialButton.iconImageSrc}
                          className='icon'
                          alt=''
                        />
                      </span>
                      <span className='text'>{socialButton.text}</span> */}
                    </Button>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign up with your username</DividerText>
                </DividerTextContainer>
                <HTMLForm onSubmit={handleRegister}>
                  <Form labelMode='floating' formData={accDetials}>
                    {/* <Input
                    type="text"
                    placeholder="Username"
                    value={accDetials.username}
                    onChange={onChange}
                    name="username"
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={accDetials.password}
                    onChange={onChange}
                    name="password"
                  /> */}
                    <GroupItem>
                      <SimpleItem dataField='username'>
                        <RequiredRule />
                      </SimpleItem>
                      <SimpleItem
                        dataField='email'
                        editorOptions={{ mode: "email" }}
                      >
                        <RequiredRule />
                      </SimpleItem>
                      <SimpleItem
                        dataField='password'
                        editorOptions={{
                          mode: "password",
                        }}
                      >
                        <RequiredRule />
                        <PatternRule
                          pattern={/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/}
                          message='Password must be at least 8 characters long and alphanumeric'
                        />
                      </SimpleItem>
                    </GroupItem>

                    <ButtonItem
                      itemType='button'
                      cssClass='btn-block'
                      horizontalAlignment='center'
                      buttonOptions={{
                        text: submitButtonText,
                        type: "default",
                        useSubmitBehavior: true,
                        icon: "pi pi-user-plus",
                      }}
                    />
                    {/* <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton> */}
                  </Form>
                  <p tw='mt-6 text-xs text-gray-600 text-center'>
                    I agree to abide by taskpip's{" "}
                    <Link
                      to={tosUrl}
                      tw='border-b border-gray-500 border-dotted'
                    >
                      Terms of Service
                    </Link>{" "}
                    and its{" "}
                    <Link
                      to={privacyPolicyUrl}
                      tw='border-b border-gray-500 border-dotted'
                    >
                      Privacy Policy
                    </Link>
                  </p>

                  <p tw='mt-8 text-sm text-gray-600 text-center'>
                    Already have an account?{" "}
                    <Link
                      to={
                        state?.from
                          ? { pathname: signInUrl, state: state.from }
                          : signInUrl
                      }
                      tw='border-b border-gray-500 border-dotted'
                    >
                      Sign In
                    </Link>
                  </p>
                </HTMLForm>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
