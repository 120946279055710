import "styled-components/macro";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
} from "devextreme-react/data-grid";
import { useState } from "react";
import { TabContent } from "components/cards/TabCardGrid";
import { Button } from "devextreme-react";
import { useAuth } from "context/auth";
import TaskDetails from "./TaskDetails";

import tw from "twin.macro";

const CompletedTasks = () => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuth();

  const closeDetailsModal = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const renderViewJobInfo = ({ data }) => {
    return (
      <Button
        type='default'
        stylingMode='outlined'
        text='View Job Details'
        css={tw`!rounded-full`}
        onClick={() => {
          setSelected(data);
          setIsOpen(true);
        }}
      />
    );
  };
  return (
    <TabContent css={tw`sm:mr-0! md:mr-0! lg:mr-0!`}>
      <DataGrid
        dataSource={user?.profile?.jobs?.completedJobs || []}
        keyExpr='jobCode'
        showBorders
        wordWrapEnabled
        columnAutoWidth={false}
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' caption='Task Code' />
        <Column dataField='title' />
        <Column dataField='jobPosterName' caption='Organization' />
        <Column dataField='totalHours' />
        {/* <Column dataField='isHoursConfirmed' dataType='boolean' /> */}
        <Column
          cellRender={renderViewJobInfo}
          alignment='center'
          showInColumnChooser={false}
          caption='Job Details'
        />
      </DataGrid>
      {selected && (
        <TaskDetails
          visible={isOpen}
          selected={selected}
          onHide={closeDetailsModal}
          isCompleted
        />
      )}
    </TabContent>
  );
};

export default CompletedTasks;
