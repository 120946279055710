import "styled-components/macro";
import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { appliedJobStatus } from "constant/data";
import RichTextEditor from "react-rte";
import tw from "twin.macro";

const TaskDetails = ({ visible, onHide, selected, isCompleted, paid }) => {
  const renderFullDescription = () => {
    return (
      <>
        {/* <span tw='text-base'>
          <span tw='text-base text-gray-700'>Full Description</span>
        </span> */}
        <RichTextEditor
          readOnly
          value={RichTextEditor.createValueFromString(
            selected.fullDescription,
            "markdown"
          )}
          editorClassName='editor'
        />
      </>
    );
  };

  const renderAttendance = (user) => {
    return (
      <DataGrid
        dataSource={
          user
            ? selected.attendance
            : selected.attendanceByEmployer.attendance || []
        }
        keyExpr='id'
        showBorders
        wordWrapEnabled
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 15]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='date' allowEditing={false} />
        <Column dataField='numberOfHours' dataType='number' />

        <Summary>
          <TotalItem
            column='numberOfHours'
            displayFormat='Total Hours: {0}'
            summaryType='sum'
          />
        </Summary>
      </DataGrid>
    );
  };

  // renderAttendance

  const renderAppliedDays = () => {
    return (
      <DataGrid
        dataSource={selected.appliedDays || selected.acceptedDays || []}
        keyExpr='id'
        showBorders
        wordWrapEnabled
        columnAutoWidth
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
      >
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 15]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='date' />
        <Column dataField='startTime' />
        <Column dataField='endTime' />
        <Column dataField='numberOfHours' />
        <Summary>
          <TotalItem
            column='numberOfHours'
            displayFormat='Total Hours: {0}'
            summaryType='sum'
          />
        </Summary>
      </DataGrid>
    );
  };

  return (
    <Popup
      title='Details'
      showCloseButton
      visible={visible}
      onHiding={onHide}
      animation={null}
      fullScreen
    >
      <ScrollView height='100%'>
        <div css={tw`p-3`}>
          <Form formData={selected} labelLocation='top' labelMode='floating'>
            <GroupItem caption='Task Details' colCount={3}>
              <SimpleItem
                dataField='jobCode'
                label={{ text: "Task Code" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='jobPosterName'
                label={{ text: "Organization" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='title'
                label={{ text: "Task Title" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='priceByHour'
                editorOptions={{ readOnly: true }}
              />
              {(selected.appliedDays || selected.acceptedDays) && (
                <SimpleItem
                  label={{ text: "Application Status" }}
                  dataField='status'
                  editorType='dxSelectBox'
                  editorOptions={{
                    readOnly: true,
                    dataSource: appliedJobStatus,
                    valueExpr: "value",
                    displayExpr: "display",
                  }}
                />
              )}
            </GroupItem>
            <GroupItem caption='Task Description'>
              <SimpleItem render={renderFullDescription} />
            </GroupItem>
            <GroupItem caption='Task Location' colCount={3}>
              <SimpleItem
                dataField='location.addressLine'
                label={{ text: "Address Line" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='location.city'
                label={{ text: "City" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='location.province'
                label={{ text: "Province" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='location.postalCode'
                label={{ text: "Postal Code" }}
                editorOptions={{ readOnly: true }}
              />
              <SimpleItem
                dataField='location.country'
                label={{ text: "Country" }}
                editorOptions={{ readOnly: true }}
              />
            </GroupItem>
            {!isCompleted &&
              (selected.appliedDays || selected.acceptedDays) && (
                <GroupItem
                  caption={
                    selected.appliedDays ? "Applied Days" : "Accepted Days"
                  }
                >
                  <SimpleItem render={renderAppliedDays} />
                </GroupItem>
              )}
            {(isCompleted || paid) && (
              <GroupItem caption={"Attendance (Added by me)"}>
                <SimpleItem render={() => renderAttendance(true)} />
              </GroupItem>
            )}

            {(isCompleted || paid) && (
              <GroupItem caption={"Attendance (Added by employer)"}>
                <SimpleItem render={() => renderAttendance(false)} />
              </GroupItem>
            )}
          </Form>
        </div>
      </ScrollView>
    </Popup>
  );
};

export default TaskDetails;
