import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import SideMenu from "components/sidebar/SideMenu";
import tw from "twin.macro";
import "components/sidebar/sidebar.css";
import ProfileHeader from "components/sidebar/ProfileHeader";
import AuthService from "service/authService";
import { LoadPanel } from "devextreme-react";
import Dashboard from "./Dashboard";
import ProfileSettings from "./ProfileSettings";
import { useAuth } from "context/auth";
import TasksPage from "./TasksPage";
import WalletPage from "./WalletPage";
import GeneralSettingsPage from "./GeneralSettingsPage";
import { getToken } from "utils";

const Pages = tw.div`
   lg:mt-24 mt-20 relative overflow-x-hidden mb-6 sm:mr-2
`;

export const Heading = tw.h1`font-thin sm:text-2xl text-lg uppercase sm:text-left text-center`;

export const LoadingContainer = tw.div`flex justify-center mt-80`;

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [toggleHeader, setToggleHeader] = useState(false);
  const { push } = useHistory();
  const { setUser, token } = useAuth();

  useEffect(() => {
    const storedToken = getToken();
    const getUser = async () => {
      try {
        const {
          data: { data },
        } = await AuthService.getUser(token || storedToken);
        setUser(data);
        setIsLoading(false);
      } catch (error) {
        push("/");
      }
    };

    getUser();
  }, [push, setUser, token]);

  // useEffect(() => {
  //   /*===== SHOW NAVBAR  =====*/
  //   const showNavbar = (toggleId, navId, bodyId, headerId) => {
  //     const toggle = document.getElementById(toggleId),
  //       nav = document.getElementById(navId),
  //       bodypd = document.getElementById(bodyId),
  //       headerpd = document.getElementById(headerId);

  //     // Validate that all variables exist
  //     if (toggle && nav && bodypd && headerpd) {
  //       toggle.addEventListener("click", () => {
  //         // show navbar
  //         nav.classList.toggle("show");
  //         // change icon
  //         // if (toggle.classList.contains("pi-bars")) {
  //         //   toggle.classList.remove("pi-bars");
  //         //   toggle.classList.add("pi-times");
  //         // } else {
  //         //   toggle.classList.remove("pi-times");
  //         //   toggle.classList.add("pi-bars");
  //         // }
  //         // add padding to body
  //         bodypd.classList.toggle("body-pd");
  //         // add padding to header
  //         headerpd.classList.toggle("body-pd");
  //       });
  //     }
  //   };

  //   showNavbar("header-toggle", "nav-bar", "body-pd", "header");

  //   // /*===== LINK ACTIVE  =====*/
  //   // const linkColor = document.querySelectorAll(".nav__link");

  //   // function colorLink() {
  //   //   if (linkColor) {
  //   //     linkColor.forEach((l) => l.classList.remove("active"));
  //   //     this.classList.add("active");
  //   //   }
  //   // }
  //   // linkColor.forEach((l) => l.addEventListener("click", colorLink));
  // }, [isLoading]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingContainer>
          <LoadPanel visible closeOnOutsideClick={false} />
        </LoadingContainer>
      ) : (
        <div tw='overflow-x-hidden!'>
          {" "}
          <ProfileHeader
            toggleHeader={toggleHeader}
            setToggleHeader={setToggleHeader}
          />
          <SideMenu
            toggleHeader={toggleHeader}
            setToggleHeader={setToggleHeader}
          />
          <Pages
            className={toggleHeader ? "profile-body body-pd" : "profile-body"}
            id='body-pd'
          >
            <Switch>
              <Route exact path='/profile/dashboard' component={Dashboard} />
              <Route
                exact
                path='/profile/settings'
                component={ProfileSettings}
              />
              <Route exact path='/profile/tasks' component={TasksPage} />
              <Route exact path='/profile/wallet' component={WalletPage} />
              <Route
                exact
                path='/profile/g-settings'
                component={GeneralSettingsPage}
              />

              <Redirect from='/profile' to='/profile/dashboard' />
              <Redirect from='*' to='/profile/dashboard' />
            </Switch>
          </Pages>
        </div>
      )}
    </React.Fragment>
  );
};

export default Profile;
