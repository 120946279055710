export const isProd = process.env.NODE_ENV === "production";

export const API_URL = isProd
  ? "https://taskpip-backend.onrender.com"
  : "http://localhost:8080";

export const CLIENT_URL = isProd
  ? "https://taskpip.com"
  : "http://localhost:3000";

export const GRAPH_ENDPOINT =
  "https://api-ca-central-1.graphcms.com/v2/cl18fg8ltjmrw01z08n9wd5k0/master";

export const PARTNERS_URL = "https://partner.taskpip.com";
