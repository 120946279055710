import GeneralSettings from "components/profile/GeneralSettings";
import { Heading } from "./Profile";

const GeneralSettingsPage = () => {
  return (
    <div>
      <Heading>General Settings</Heading>
      <GeneralSettings />
    </div>
  );
};

export default GeneralSettingsPage;
