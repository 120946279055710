import "styled-components/macro";
import React, { useState, useMemo } from "react";
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Legend,
} from "devextreme-react/pie-chart";
import Calendar from "devextreme-react/calendar";
import StatusCard from "components/cards/StatusCard";
import tw from "twin.macro";
import useIsDesktopOrLaptop from "hooks/useIsDesktopOrLaptop";
import { Heading } from "./Profile";
import InProgress from "components/tasks/InProgress";
import { formartNumberUtil } from "utils";
import { Button } from "devextreme-react";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/auth";

const GridContainer = tw.div`grid lg:grid-cols-2 grid-cols-1 gap-4`;

const Dashboard = () => {
  const isDesktopOrLaptop = useIsDesktopOrLaptop();
  const [zoomLevel, setZommlevel] = useState("month");
  const [currentCalenderValue, setCurrentValue] = useState(new Date());
  const { push } = useHistory();

  const { user } = useAuth();

  const dataSource = useMemo(
    () => [
      {
        name: "Accepted",
        area:
          user?.profile?.totalAcceptedJobs / user?.profile.totalJobsApplied ||
          0,
      },
      {
        name: "Rejected",
        area:
          user?.profile?.totalRejectedJobs / user?.profile.totalJobsApplied ||
          0,
      },
      {
        name: "In Review",
        area:
          user?.profile?.totalInReviewJobs / user?.profile.totalJobsApplied ||
          0,
      },
    ],
    [user]
  );

  const customizePoint = (point) => {
    if (point.argument === "In Review") {
      return {
        color: "#b7791f",
      };
    } else if (point.argument === "Rejected") {
      return {
        color: "#e53e3e",
      };
    }
  };

  return (
    <div>
      <Heading>Dashboard</Heading>
      <div css={[tw`mt-4`]}>
        <GridContainer>
          <GridContainer>
            <StatusCard css={[tw`bg-indigo-700`]}>
              <i className='pi pi-dollar' css={[tw`text-3xl text-white`]}></i>
              <div css={[tw`flex flex-col`]}>
                <span css={[tw`text-sm text-white`]}>Total Earnings</span>
                <span css={[tw`text-white font-semibold text-lg`]}>
                  {formartNumberUtil(user?.profile?.totalEarnings || 0)}
                </span>
              </div>
            </StatusCard>
            <StatusCard css={[tw`bg-yellow-700`]}>
              <i
                className='pi pi-briefcase'
                css={[tw`text-3xl text-white`]}
              ></i>
              <div css={[tw`flex flex-col`]}>
                <span css={[tw`text-sm text-white`]}>Total Jobs Applied</span>
                <span css={[tw`text-white font-semibold text-lg`]}>
                  {user?.profile?.totalJobsApplied || 0}
                </span>
              </div>
            </StatusCard>
            <StatusCard css={[tw`bg-primary-500`]}>
              <i
                className='pi pi-briefcase'
                css={[tw`text-3xl text-white`]}
              ></i>
              <div css={[tw`flex flex-col`]}>
                <span css={[tw`text-sm text-white`]}>Total Paid Jobs</span>
                <span css={[tw`text-white font-semibold text-lg`]}>
                  {" "}
                  {user?.profile?.totalPaidJobs || 0}
                </span>
              </div>
            </StatusCard>
            <StatusCard css={[tw`bg-green-600`]}>
              <i
                className='pi pi-briefcase'
                css={[tw`text-3xl text-white`]}
              ></i>
              <div css={[tw`flex flex-col`]}>
                <span css={[tw`text-sm text-white`]}>Total Completed Jobs</span>
                <span css={[tw`text-white font-semibold text-lg`]}>
                  {user?.profile?.totalCompletedJobs || 0}
                </span>
              </div>
            </StatusCard>
          </GridContainer>
          <StatusCard css={[tw`justify-center`]}>
            <div>
              <PieChart
                id='pie'
                dataSource={dataSource}
                animation={false}
                title={`Application Ratio - Total Applcations (${
                  user?.profile?.totalJobsApplied || 0
                })`}
                style={{ height: isDesktopOrLaptop ? "300px" : "auto" }}
                customizePoint={customizePoint}
              >
                <Series argumentField='name' valueField='area'>
                  <Label visible={true} format='percent'>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>

                <Size width={isDesktopOrLaptop ? 350 : 300} />

                {isDesktopOrLaptop ? (
                  <Legend visible={true} />
                ) : (
                  <Legend
                    css={[tw`mt-2`]}
                    visible={true}
                    horizontalAlignment='center'
                    verticalAlignment='bottom'
                  />
                )}
              </PieChart>
            </div>
          </StatusCard>
        </GridContainer>
        <div css={[tw`mt-6`]}>
          <GridContainer>
            <StatusCard>
              <div>
                <h1 css={[tw`leading-5 sm:text-xl text-lg font-thin`]}>
                  In Progress Tasks
                </h1>
                <InProgress defaultPageSize={3} allowedPageSizes={[3, 5, 10]} />
                <Button
                  type='default'
                  text='View All'
                  stylingMode='text'
                  css={[tw`mt-2 !rounded-full`]}
                  onClick={() =>
                    push({
                      pathname: "/profile/tasks",
                      state: { status: "in-progress" },
                    })
                  }
                />
              </div>
            </StatusCard>
            <StatusCard css={[tw`justify-center`]}>
              <div css={[tw`w-full`]}>
                <Calendar
                  id='calendar-container'
                  width={"auto"}
                  showTodayButton={true}
                  value={currentCalenderValue}
                  onValueChanged={(e) => {
                    setCurrentValue(e.value);
                  }}
                  onOptionChanged={(e) => {
                    if (e.name === "zoomLevel") {
                      setZommlevel(e.value);
                    }
                  }}
                  zoomLevel={zoomLevel}
                />
              </div>
            </StatusCard>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
