import "styled-components/macro";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Lookup,
  Editing,
} from "devextreme-react/data-grid";
import { TabContent } from "components/cards/TabCardGrid";
import { Badge } from "components/misc/Badge";
import { Button } from "devextreme-react";
import { appliedJobStatus } from "constant/data";
import { useState } from "react";
import { useAuth } from "context/auth";
import TaskDetails from "./TaskDetails";
import "devextreme/integration/jquery";
import tw from "twin.macro";
import JobService from "service/jobService";
import { showNotification } from "utils/notification";

// jobId: string;
//   title: string;
//   description: string;
//   numberOfDays: number;
//   isPermanent?: boolean;
//   startDate: string;
//   endDate: string;
//   location: IAddress;
//   isPaidByHour?: boolean;
//   priceByHour?: number;
//   price?: number;

export const testData = [
  {
    title: "Plumber",
    startDate: "21.01.2022",
    endDate: "30.01.2022",
    status: "in-review",
    jobPoster: "Taskpip",
    jobId: 1,
  },
  {
    title: "Office Assitant",
    startDate: "21.01.2022",
    endDate: "30.01.2022",
    status: "rejected",
    jobPoster: "Indian home",
    jobId: 2,
  },
  {
    title: "Sorter",
    startDate: "21.01.2022",
    endDate: "30.01.2022",
    status: "accepted",
    jobPoster: "Amazon",
    jobId: 3,
  },
];

const AllTasks = () => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { user, getUser } = useAuth();

  const closeDetailsModal = () => {
    setSelected(null);
    setIsOpen(false);
  };

  const onRowRemoving = async ({ key }) => {
    try {
      await JobService.deleteJob(key, "appliedJobs");
      showNotification("Successful", "success");
      getUser(true);
    } catch (error) {
      showNotification(error, "error");
      getUser(true);
    }
  };

  const cellPrepared = (options) => {
    const status = ["accepted", "rejected"];
    if (options.rowType === "data" && options.column.command === "edit") {
      const $links = options.cellElement.find(".dx-link");
      if (!status.includes(options.row.data.status))
        $links.filter(".dx-link-delete").remove();
    }
  };

  const renderStatus = ({ data }) => {
    switch (data.status) {
      case "in-review":
        return <Badge css={tw`bg-yellow-700 text-yellow-200`}>In Review</Badge>;
      case "accepted":
        return <Badge css={tw`bg-primary-500 text-white`}>Accepted</Badge>;
      case "rejected":
        return <Badge css={tw`bg-red-600 text-red-200`}>Rejected</Badge>;
      default:
        return <span>{data.status}</span>;
    }
  };

  // const renderAction = ({ data }) => {
  //   switch (data.status) {
  //     case "in-review":
  //       return (
  //         <Button
  //           type='danger'
  //           text='Withdraw'
  //           hint='Withdraw Application'
  //           tw='rounded-full'
  //         />
  //       );

  //     default:
  //       return (
  //         <i
  //           className='dx-link-delete dx-icon-trash dx-link-icon'
  //           tw='text-lg cursor-pointer'
  //         ></i>
  //       );
  //   }
  // };

  const renderViewJobInfo = ({ data }) => {
    return (
      <Button
        type='default'
        stylingMode='outlined'
        text='View Details'
        css={tw`!rounded-full`}
        onClick={() => {
          setSelected(data);
          setIsOpen(true);
        }}
      />
    );
  };

  return (
    <TabContent css={tw`sm:mr-0! md:mr-0! lg:mr-0!`}>
      {/* <h3 tw='text-lg mb-5'>Applied Tasks</h3> */}
      <DataGrid
        dataSource={user?.profile?.jobs?.appliedJobs || []}
        keyExpr='jobCode'
        showBorders
        wordWrapEnabled
        columnAutoWidth={false}
        showColumnLines
        showRowLines
        columnHidingEnabled
        rowAlternationEnabled
        onCellPrepared={cellPrepared}
        onRowRemoving={onRowRemoving}
      >
        <Editing
          mode='row'
          allowDeleting={true}
          allowAdding={false}
          allowUpdating={false}
        />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 20]}
          showNavigationButtons={true}
          showInfo={true}
        />
        <ColumnChooser enabled={true} mode='select' allowSearch={true} />
        <Column dataField='jobCode' caption='Task Code' />
        <Column dataField='title' caption='Task Title' />
        <Column dataField='jobPosterName' caption='Organization' />
        {/* <Column dataField='startDate' dataType='date' />
        <Column dataField='endDate' dataType='date' /> */}
        <Column
          dataField='status'
          caption='Application Status'
          cellRender={renderStatus}
          alignment='center'
          allowHeaderFiltering={true}
          allowFiltering={false}
        >
          <Lookup
            dataSource={appliedJobStatus}
            valueExpr='value'
            displayExpr='display'
          />
        </Column>
        <Column
          cellRender={renderViewJobInfo}
          alignment='center'
          showInColumnChooser={false}
          caption='Task Details'
        />
        {/* <Column
          cellRender={renderAction}
          alignment='center'
          showInColumnChooser={false}
        /> */}
      </DataGrid>
      {selected && (
        <TaskDetails
          visible={isOpen}
          selected={selected}
          onHide={closeDetailsModal}
        />
      )}
    </TabContent>
  );
};

export default AllTasks;
