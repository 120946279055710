import React, { useState, createContext, useContext, useEffect } from "react";
import { formatProfieData } from "utils";
import { useAuth } from "./auth";

const initialFormDataState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  address: {
    houseNumber: "",
    apartNumber: "",
    street: "",
    city: "",
    country: "Canada",
    province: "",
    postalCode: "",
  },
  jobPrefrence: "openToAny",
  workPrefrence: "",
  employmentStatus: "",
  residenceStatus: "",
  paymentType: "",
  eTransferEmail: "",
  payOutType: "bi-weekly",
  sinNumber: "",
  bankDetails: {
    accountNumber: "",
    institutionNumber: "",
    transitNumber: "",
    accountType: "",
  },
  emergencyContact: {
    name: "",
    phone: "",
    email: "",
    address: {
      houseNumber: "",
      apartNumber: "",
      street: "",
      city: "",
      country: "Canada",
      province: "",
      postalCode: "",
    },
    relationship: "",
  },
  education: "",
  documentType: "",
  residenceOther: "",
  employementOther: "",
  relationshipOther: "",
  educationOther: "",
  docTypeOther: "",
};

const initialContextState = {
  formData: initialFormDataState,
  userPhone: "",
  emerPhone: "",
  setFormData: () => {},
  // setUserPhone: () => {},
  // setEmerPhone: () => {},
  onValueChanged: (e, name) => {},
  employmentStatus: () => {},
  setEmploymentStatus: () => {},
  idDocURLS: [
    {
      URL: "",
      id: "",
      type: "",
      fileName: "",
    },
  ],
  setIdDocURLS: () => {},
  setIsEdited: () => {},
  isEdited: false,
};

const AppGlobalStateContext = createContext(initialContextState);

const AppGlobalStateProvider = (props) => {
  const [formData, setFormData] = useState(
    JSON.parse(JSON.stringify(initialFormDataState))
  );
  // const [userPhone, setUserPhone] = useState("");
  // const [emerPhone, setEmerPhone] = useState("1234");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [idDocURLS, setIdDocURLS] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (user && user.profile) {
      setFormData((prev) => ({ ...prev, ...formatProfieData(user.profile) }));
      setIdDocURLS(user.profile.idDocURLS);
      // setUserPhone(user.profile.phone);
      // setEmerPhone(user.profile.emergencyContact.phone);
      // localStorage.setItem(
      //   `${user?.username}-${user?.provider}-emerPhone`,
      //   user.profile.emergencyContact.phone
      // );
    } else {
      setIdDocURLS([]);
      setFormData(JSON.parse(JSON.stringify(initialFormDataState)));
      // setUserPhone("");
      // localStorage.setItem(`${user?.username}-${user?.provider}-emerPhone`, "");
      // setEmerPhone("");
    }
  }, [user]);

  const onValueChanged = (e, name) => {
    const { value } = e;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <React.Fragment>
      <AppGlobalStateContext.Provider
        value={{
          formData,
          // userPhone,
          // emerPhone,
          // setUserPhone,
          // setEmerPhone,
          setFormData,
          onValueChanged,
          setEmploymentStatus,
          employmentStatus,
          idDocURLS,
          setIdDocURLS,
          setIsEdited,
          isEdited,
        }}
        {...props}
      />
    </React.Fragment>
  );
};

const useAppGlobalState = () => useContext(AppGlobalStateContext);
export { AppGlobalStateProvider, useAppGlobalState };
