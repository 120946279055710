import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "context/auth";
import "twin.macro";
import { Tooltip } from "devextreme-react";
import useIsDesktopOrLaptop from "hooks/useIsDesktopOrLaptop";
import { useAppGlobalState } from "context/appGlobalState";
import { confirm } from "devextreme/ui/dialog";
import AuthService from "service/authService";

const SideMenu = ({ toggleHeader, setToggleHeader }) => {
  const [visible, setVisible] = useState("");

  const {
    location: { pathname },
    push,
  } = useHistory();

  const { logout, setUser } = useAuth();
  const { isEdited, setIsEdited } = useAppGlobalState();

  const isDesktopOrLaptop = useIsDesktopOrLaptop();

  const getUser = async () => {
    try {
      const {
        data: { data },
      } = await AuthService.getUser();
      setUser(data);
    } catch (error) {}
  };

  const changeRoute = async (path) => {
    if (isEdited) {
      const result = await confirm(
        "You have unsaved changes. Do you want to continue without saving them? ",
        "Confirm changes"
      );
      if (result) {
        getUser();
        setIsEdited(false);
        push(path);
      } else {
        return;
      }
    } else {
      push(path);
    }
  };

  const toggle = (id) => {
    setVisible(id);
  };

  return (
    <div className={toggleHeader ? "l-navbar show" : "l-navbar"} id='nav-bar'>
      <nav className='nav'>
        <div>
          <div
            tw='cursor-pointer'
            className='nav__logo'
            id='home'
            onMouseEnter={() => toggle("home")}
            onMouseLeave={() => toggle("")}
            onClick={() => changeRoute("/")}
          >
            <span
              className='nav__logo-icon'
              tw={"bg-primary-500 rounded-full text-center"}
            >
              <i className='pi pi-home nav__icon'></i>
            </span>

            <span className='nav__logo-name'>Homepage</span>
            {isDesktopOrLaptop ? (
              <>
                {!toggleHeader ? (
                  <Tooltip
                    target='#home'
                    visible={visible === "home"}
                    position={"right"}
                  >
                    <div tw='text-base font-semibold'>Homepage</div>
                  </Tooltip>
                ) : null}
              </>
            ) : null}
          </div>

          <div className='nav__list'>
            <div
              className={
                pathname === "/profile/dashboard"
                  ? "nav__link active"
                  : "nav__link"
              }
              id='dash'
              onMouseEnter={() => toggle("dash")}
              onMouseLeave={() => toggle("")}
              onClick={() => {
                !isDesktopOrLaptop && setToggleHeader(false);
                changeRoute("/profile/dashboard");
              }}
              tw='cursor-pointer'
            >
              <i className='pi pi-th-large nav__icon '></i>

              <span className='nav__name '>Dashboard</span>

              {isDesktopOrLaptop ? (
                <>
                  {!toggleHeader ? (
                    <Tooltip
                      target='#dash'
                      visible={visible === "dash"}
                      position={"right"}
                    >
                      <div tw='text-base font-semibold'>Dashboard</div>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div>

            <Link
              to='/profile/settings'
              className={
                pathname === "/profile/settings"
                  ? "nav__link active"
                  : "nav__link"
              }
              id='profile'
              onMouseEnter={() => toggle("profile")}
              onMouseLeave={() => toggle("")}
              onClick={() => {
                !isDesktopOrLaptop && setToggleHeader(false);
              }}
            >
              <i className='pi pi-user-edit nav__icon profile'></i>
              <span className='nav__name'>Profile Setting</span>

              {isDesktopOrLaptop ? (
                <>
                  {!toggleHeader ? (
                    <Tooltip
                      target='#profile'
                      visible={visible === "profile"}
                      position={"right"}
                    >
                      <div tw='text-base font-semibold'>Profile Setting</div>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </Link>

            <div
              className={
                pathname === "/profile/tasks" ? "nav__link active" : "nav__link"
              }
              id='tasks'
              onMouseEnter={() => toggle("tasks")}
              onMouseLeave={() => toggle("")}
              onClick={() => {
                !isDesktopOrLaptop && setToggleHeader(false);
                changeRoute("/profile/tasks");
              }}
              tw='cursor-pointer'
            >
              <i className='pi pi-briefcase nav__icon'></i>
              <span className='nav__name'>Tasks</span>
              {isDesktopOrLaptop ? (
                <>
                  {!toggleHeader ? (
                    <Tooltip
                      target='#tasks'
                      visible={visible === "tasks"}
                      position={"right"}
                    >
                      <div tw='text-base font-semibold'>Tasks</div>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div>

            {/* <div
              className={
                pathname === "/profile/wallet"
                  ? "nav__link active"
                  : "nav__link"
              }
              id='wallet'
              onMouseEnter={() => toggle("wallet")}
              onMouseLeave={() => toggle("")}
              onClick={() => {
                !isDesktopOrLaptop && setToggleHeader(false);
                changeRoute("/profile/wallet");
              }}
              tw='cursor-pointer'
            >
              <i className='pi pi-wallet nav__icon'></i>
              <span className='nav__name'>Wallet</span>
              {isDesktopOrLaptop ? (
                <>
                  {!toggleHeader ? (
                    <Tooltip
                      target='#wallet'
                      visible={visible === "wallet"}
                      position={"right"}
                    >
                      <div tw='text-base font-semibold'>Wallet</div>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div> */}
            <div
              className={
                pathname === "/profile/g-settings"
                  ? "nav__link active"
                  : "nav__link"
              }
              id='j-settings'
              onMouseEnter={() => toggle("j-settings")}
              onMouseLeave={() => toggle("")}
              onClick={() => {
                !isDesktopOrLaptop && setToggleHeader(false);
                changeRoute("/profile/g-settings");
              }}
              tw='cursor-pointer'
            >
              <i className='pi pi-cog nav__icon'></i>
              <span className='nav__name'>Settings</span>
              {isDesktopOrLaptop ? (
                <>
                  {!toggleHeader ? (
                    <Tooltip
                      target='#j-settings'
                      visible={visible === "j-settings"}
                      position={"right"}
                    >
                      <div tw='text-base font-semibold'>General Settings</div>
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>

        <span
          className='nav__link'
          tw='cursor-pointer'
          onClick={logout}
          id='logout'
          onMouseEnter={() => toggle("logout")}
          onMouseLeave={() => toggle("")}
        >
          <i className='pi pi-sign-out nav__icon'></i>
          <span className='nav__name'>Log Out</span>

          {isDesktopOrLaptop ? (
            <>
              {!toggleHeader ? (
                <Tooltip
                  target='#logout'
                  visible={visible === "logout"}
                  position={"right"}
                >
                  <div tw='text-base font-semibold'>Log Out</div>
                </Tooltip>
              ) : null}
            </>
          ) : null}
        </span>
      </nav>
    </div>
  );
};

export default SideMenu;
