import "styled-components/macro";
import React from "react";
import tw from "twin.macro";

import moment from "moment";

const PostDetail = ({ post }) => {
  const getContentFragment = (index, text, obj, type) => {
    let modifiedText = text;

    if (obj) {
      if (obj.bold) {
        modifiedText = <b key={index}>{text}</b>;
      }

      if (obj.italic) {
        modifiedText = <em key={index}>{text}</em>;
      }

      if (obj.underline) {
        modifiedText = <u key={index}>{text}</u>;
      }
    }

    switch (type) {
      case "heading-three":
        return (
          <h3 key={index} css={tw`text-xl font-semibold mb-4`}>
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </h3>
        );
      case "paragraph":
        return (
          <p key={index} css={tw`mb-8`}>
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </p>
        );
      case "heading-four":
        return (
          <h4 key={index} css={tw`text-sm font-semibold mb-4`}>
            {modifiedText.map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </h4>
        );
      case "image":
        return (
          <img
            key={index}
            alt={obj.title}
            height={obj.height}
            width={obj.width}
            src={obj.src}
          />
        );
      default:
        return modifiedText;
    }
  };

  return (
    <>
      <div css={tw`bg-white shadow-lg  lg:p-8 pb-12 mb-8`}>
        <div css={tw`relative overflow-hidden shadow-md mb-6`}>
          <img
            src={post.featuredImage.url}
            alt=''
            css={tw`object-top h-full w-full object-cover  shadow-lg sm:rounded-t-lg lg:rounded-lg`}
          />
        </div>
        <div css={tw`px-4 lg:px-0`}>
          <div css={tw`flex items-center mb-8 w-full`}>
            <div
              css={tw`hidden md:flex  justify-center lg:mb-0 lg:w-auto mr-8 items-center`}
            >
              <img
                alt={post.author.name}
                height='30px'
                width='30px'
                css={tw`align-middle rounded-md`}
                src={post.author.photo.url}
              />
              <p
                css={tw`inline align-middle text-gray-700 ml-2 font-medium text-lg`}
              >
                {post.author.name}
              </p>
            </div>
            <div css={tw`font-medium text-gray-700`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                css={tw`h-6 w-6 inline mr-2 text-primary-500`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
                />
              </svg>
              <span css={tw`align-middle`}>
                {moment(post.createdAt).format("MMM DD, YYYY")}
              </span>
            </div>
          </div>
          <h1 css={tw`mb-8 text-3xl font-semibold`}>{post.title}</h1>
          {post.content.raw.children.map((typeObj, index) => {
            const children = typeObj.children.map((item, itemindex) =>
              getContentFragment(itemindex, item.text, item)
            );

            return getContentFragment(index, children, typeObj, typeObj.type);
          })}
        </div>
      </div>
    </>
  );
};

export default PostDetail;
