import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "FAQS",
  heading = "You have Questions ?",
  description = "And we have got answers to all of them.",
  faqs = [
    {
      question: "I forgot my password ? ",
      answer: `If you forgot your taskpip account password or just want to change it, you can recover it anytime. Just tap/click forgot password on the web login screen.
        Please follow our password strength recommendations. `,
    },
    // {
    //   question: "Is it possible to change my account email address ?  ",
    //   answer: (
    //     <p>
    //       If your email address is no longer active, you may need to change your
    //       Taskpip account email address (your login).
    //       <br /> <br />
    //       Contact the{" "}
    //       <a href='mailto:support@taskpip.com' tw={"text-blue-500 "}>
    //         Taskpip support team{" "}
    //       </a>{" "}
    //       and request the address change. In order to ensure your account's
    //       security, be ready to provide additional verification information like
    //       your ID, A picture of you holding your ID and your signature on a
    //       white sheet of paper with the current date, and address.
    //     </p>
    //   ),
    // },
    // {
    //   question: "What is two-factor authentication (2FA) ?",
    //   answer: (
    //     <p>
    //       Two-factor authentication (2FA) is a security process which requires
    //       users to provide two means of identification before accessing their
    //       account.
    //       <br /> <br />
    //       The two levels of security are your password and a unique special code
    //       generated by an authentication app, installed on your smartphone. The
    //       two most popular authentication apps are Google Authenticator and
    //       Authy.
    //       <br /> <br />
    //       If you have 2FA enabled, you will receive a temporary 6-digit code
    //       which can be used only for a very short period of time: about 20
    //       seconds. After 20 seconds, the code will expire and a new code will be
    //       generated. The requirement to enter both your password and this
    //       6-digit code makes your account better protected. Even if your
    //       password is compromised (make sure to never reveal it to anyone!),
    //       your account cannot be accessed without direct access to your mobile
    //       device.
    //     </p>
    //   ),
    // },
    {
      question: "Who is really the employer ? ",
      answer: (
        <p>
          Those who work on our site generally work under Taskpip. We have
          several customers (such as Manpower) who make their own contracts with
          its employees, but in general all contracts are made through us. This
          means that the company where you work doesn't pay your wages directly.
          Should in case you have a question, you can reach out to{" "}
          <a href='mailto:support@taskpip.com' tw={"text-blue-500 "}>
            support@taskpip.com{" "}
          </a>
          .
        </p>
      ),
    },
    // {
    //   question: "How Do I Confirm My Email Address and Account ?",
    //   answer: (
    //     <p>
    //       When you sign up on Taskpip, we will send you a confirmation email to
    //       confirm your account. Check the inbox of the email address you used to
    //       register.
    //       <br /> <br />
    //       The account confirmation email includes a Confirm Your Email Address
    //       link. Click this link to confirm your account.
    //       <br /> <br />
    //       If you didn't receive a confirmation email, check your spam or junk
    //       folders to see if the email was directed there. Please also include{" "}
    //       <a href='mailto:support@taskpip.com' tw={"text-blue-500 "}>
    //         support@taskpip.com{" "}
    //       </a>{" "}
    //       as a safe sender for any spam blocking software.
    //       <br /> <br />
    //       If you are still unable to find your account confirmation email or if
    //       you have difficulty confirming your account, please submit a request
    //       to our support team using the above email.
    //     </p>
    //   ),
    // },
    {
      question: "How to apply to a job on taskpip",
      answer: (
        <p>
          We’re so happy you're choosing to use taskpip to assist in your work
          search! Please note that taskpip does not operate as a recruiting
          service, but rather functions as a platform that connects employers
          looking for workers to get their daily task done. With time taskpip
          will be adding career advice or career-related resources as a guide
          for our users looking for jobs.
          <br /> <br />
          To apply for jobs on taskpip, first navigate to our homepage. Here
          you'll find text boxes labeled "Looking for work." In the "Looking for
          work" box, you can create a profile as a work seeker, this gives you
          the advantage to have access to additional features as a work seeker.
          After providing necessary document requested during the registration
          in other to get you verified. This is because we are trying as much as
          possible to avoid workers impersonating another person.
          <br /> <br />
          To find your desired work in a certain area, the dashboard contains
          list of available work in a location with description showing city,
          province/territory, or postal code where the job is located.
          <br /> <br />
          Best of luck in your work search!
        </p>
      ),
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className='group'
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial='collapsed'
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial='collapsed'
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
