import "styled-components/macro";
import React, { useState, useEffect } from "react";
import { getAdjacentPosts } from "service/blogService";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import AdjacentPostCard from "./AdjacentPostCard";

const StyledDiv = styled.div`
  ${tw`rounded-lg relative h-72`}
  ${(props) =>
    props.isColFour
      ? css`
          ${tw`col-span-1 lg:col-span-4`}
        `
      : css`
          ${tw`col-span-1 lg:col-span-8`}
        `}
`;

const AdjacentPosts = ({ createdAt, slug }) => {
  const [adjacentPost, setAdjacentPost] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getAdjacentPosts(createdAt, slug).then((result) => {
      setAdjacentPost(result);
      setDataLoaded(true);
    });
  }, [slug, createdAt]);

  return (
    <div css={tw`grid grid-cols-1 lg:grid-cols-8 gap-12 mb-8`}>
      {dataLoaded && (
        <>
          {adjacentPost.previous && (
            <StyledDiv
              //   tw={`${
              //     adjacentPost.next
              //       ? "col-span-1 lg:col-span-4"
              //       : "col-span-1 lg:col-span-8"
              //   } adjacent-post rounded-lg relative h-72`}
              className='adjacent-post'
            >
              <AdjacentPostCard post={adjacentPost.previous} position='LEFT' />
            </StyledDiv>
          )}
          {adjacentPost.next && (
            <StyledDiv
              //   tw={`${
              //     adjacentPost.previous
              //       ? "col-span-1 lg:col-span-4"
              //       : "col-span-1 lg:col-span-8"
              //   } adjacent-post rounded-lg relative h-72`}
              className='adjacent-post'
            >
              <AdjacentPostCard post={adjacentPost.next} position='RIGHT' />
            </StyledDiv>
          )}
        </>
      )}
    </div>
  );
};

export default AdjacentPosts;
