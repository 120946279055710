import "styled-components/macro";
import React from "react";
import {
  ThreeColumnContainer,
  DecoratorBlob,
} from "components/features/ThreeColWithSideImage";
import { LoadPanel } from "devextreme-react";
import { Container } from "components/misc/Layouts";
import { Subheading, SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
import ad from "images/ad.svg";
import Image from "components/misc/Image";
import { Button } from "devextreme-react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import JobService, { jobServiceQueryKeys } from "service/jobService";
import { getLastChar } from "utils";
import { showNotification } from "utils/notification";
import { Message } from "primereact/message";

const HighlightedText = tw.span`text-primary-500`;

const JobContainer = tw.div`my-4 w-full`;
const JobCard = tw.div`grid lg:grid-cols-2 grid-cols-1 gap-4 rounded-md border border-primary-500 shadow-md p-4 text-[15px]!`;
const Column = tw.div`flex flex-col space-y-4`;
export const Flex = tw.div`flex items-center gap-2 mb-2 lg:flex-row flex-col`;

const initialValue = 0;
export const sumJobHours = (jobDays) =>
  jobDays.reduce(
    (previousValue, currentValue) => previousValue + currentValue.numberOfHours,
    initialValue
  );

const Jobs = () => {
  const { push } = useHistory();

  const { data, isLoading, isError, error } = useQuery(
    jobServiceQueryKeys.GET_ALL_JOBS,
    async () => JobService.getAllJobs("accepting-application")
  );

  if (isError) {
    showNotification(error, "error");
  }

  return (
    <Container>
      <ThreeColumnContainer>
        <Subheading tw='uppercase'>Task Offers</Subheading>
        {!isLoading && (
          <SectionHeading css={tw`mb-6`}>
            Find a <HighlightedText>task</HighlightedText> and choose a suitable{" "}
            <HighlightedText>time</HighlightedText> that is good for you!!
          </SectionHeading>
        )}
        {isLoading && <LoadPanel visible={true} />}
        {data?.data.length ? (
          <>
            {data.data.map((job) => {
              return (
                <JobContainer key={job.jobCode}>
                  <JobCard>
                    <Column>
                      <Flex>
                        <i
                          className='pi pi-tag'
                          css={tw`text-primary-500 text-lg`}
                        ></i>
                        <Subheading css={tw`text-xl`}>{job.title}</Subheading>
                      </Flex>

                      <Flex>
                        <i
                          className='pi pi-dollar'
                          css={tw`text-primary-500 text-lg`}
                        ></i>
                        <h5>
                          <strong>
                            {sumJobHours(job.taskDays) * job.priceByHour} C$
                          </strong>{" "}
                          - {job.priceByHour} C$/h
                        </h5>
                      </Flex>

                      <Image src={job.jobImgUrl || ad} />
                      <div>
                        <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                          Task Description
                        </h5>
                        <p css={tw`lg:text-left text-center`}>{`${
                          job.shortDescription
                        }${
                          getLastChar(job.shortDescription) === "."
                            ? ".."
                            : "..."
                        }`}</p>
                      </div>
                    </Column>
                    <Column>
                      <div>
                        <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                          Task Poster
                        </h5>
                        <Flex>
                          <i
                            className='pi pi-building'
                            css={tw`text-primary-500 text-lg`}
                          ></i>
                          <h5 css={tw`lg:text-left text-center`}>
                            {job.jobPosterName}
                          </h5>
                        </Flex>
                      </div>
                      <div>
                        <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                          Task Location
                        </h5>
                        <Flex>
                          <i
                            className='pi pi-map-marker'
                            css={tw`text-primary-500 text-lg`}
                          ></i>
                          <h5
                            css={tw`lg:text-left text-center`}
                          >{`${job.location.addressLine}, ${job.location.city}, ${job.location.province} ${job.location.country}, ${job.location.postalCode}.`}</h5>
                        </Flex>
                      </div>
                      <div>
                        <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                          Date and Time
                        </h5>
                        {job.taskDays.map((day) => {
                          return (
                            <Flex
                              css={tw`mb-3 lg:text-left text-center`}
                              key={day.id}
                            >
                              <i
                                className='pi pi-clock'
                                css={tw`text-primary-500 text-lg`}
                              ></i>
                              <span>{day.date}</span>

                              <span>
                                {day.startTime} - {day.endTime} (
                                {day.numberOfHours}hrs)
                              </span>
                            </Flex>
                          );
                        })}
                      </div>
                    </Column>
                    <div css={tw`lg:col-span-2`}>
                      <Button
                        text='Check out this task'
                        css={tw`w-full !rounded-full`}
                        type='default'
                        onClick={() => push(`/task/${job._id}`)}
                      />
                    </div>
                  </JobCard>
                </JobContainer>
              );
            })}
          </>
        ) : (
          <>
            {!isLoading && (
              <Message
                severity='info'
                css={tw`w-full justify-start mt-6`}
                text={`No task(s) available at the moment. Please check back as we are
            actively adding new tasks.`}
              />
            )}
          </>
        )}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default Jobs;
