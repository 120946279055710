import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Jobs from "components/jobs/Jobs";

const JobsPage = () => {
  return (
    <AnimationRevealPage disabled>
      <Header roundedHeaderButton />
      <Jobs />
      <Footer />
    </AnimationRevealPage>
  );
};

export default JobsPage;
