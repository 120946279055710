import StatusCard from "components/cards/StatusCard";
import React from "react";
import tw from "twin.macro";
import { formartNumberUtil } from "utils";

const GridContainer = tw.div`grid lg:grid-cols-3 grid-cols-1 gap-4`;

const Wallet = () => {
  return (
    <div tw='mt-4'>
      <GridContainer>
        <StatusCard tw='bg-yellow-700'>
          <i className='pi pi-dollar' tw='text-3xl text-white'></i>
          <div tw='flex flex-col'>
            <span tw='text-sm text-white'>Total Amount Unpaid</span>
            <span tw='text-white font-semibold text-lg'>
              {formartNumberUtil(5000)}
            </span>
          </div>
        </StatusCard>
        <StatusCard tw='bg-green-600'>
          <i className='pi pi-dollar' tw='text-3xl text-white'></i>
          <div tw='flex flex-col'>
            <span tw='text-sm text-white'>Total Paid Amount</span>
            <span tw='text-white font-semibold text-lg'>
              {formartNumberUtil(10000)}
            </span>
          </div>
        </StatusCard>
        <StatusCard tw='bg-indigo-700'>
          <i className='pi pi-dollar' tw='text-3xl text-white'></i>
          <div tw='flex flex-col'>
            <span tw='text-sm text-white'>Total Earnings</span>
            <span tw='text-white font-semibold text-lg'>
              {formartNumberUtil(15000)}
            </span>
          </div>
        </StatusCard>
      </GridContainer>
    </div>
  );
};

export default Wallet;
