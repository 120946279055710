import API from "../api/apiConfig";

const AuthService = {
  loginUser: async (loginDetails) => {
    return await API.post("/auth/login", {
      ...loginDetails,
      accountType: "worker",
    });
  },

  createUser: async (accDetails) => {
    return await API.post("/auth/register", accDetails);
  },

  getUser: async (token) => {
    if (token) {
      return await API.get("/auth/me", {
        headers: { token },
        params: { accountType: "worker" },
      });
    }
    return await API.get("/auth/me", {
      params: { accountType: "worker" },
    });
  },

  updatePassword: async (data) => {
    return await API.post(`/auth/updatepassword`, data);
  },

  logout: async () => {
    return await API.post("/auth/logout");
  },

  requestResetPassword: async (email) => {
    return await API.post("/auth/requestresetpassword", { email });
  },

  resetPassword: async (password, token) => {
    return await API.put(`/auth/resetpassword/${token}`, { password });
  },
};

export default AuthService;
