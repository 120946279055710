import "styled-components/macro";
import { useEffect, useState } from "react";
import {
  HeaderRow,
  TabsControl,
  TabControl,
} from "components/cards/TabCardGrid";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import AllTasks from "./AllTasks";
import InProgress from "./InProgress";
import CompletedTasks from "./CompletedTasks";
import PaidTasks from "./PaidTasks";
// import { Button } from "devextreme-react";
import { locale } from "devextreme/localization";
import AcceptedTasks from "./AcceptedTasks";

const dataSource = [
  { title: "Applied Tasks", type: "applied", index: 0 },
  { title: "Accepted Tasks", type: "accepted", index: 1 },
  { title: "In Progress Tasks", type: "in-progress", index: 2 },
  { title: "Completed Tasks", type: "completed", index: 3 },
  { title: "Paid Tasks", type: "paid", index: 4 },
];

const Tasks = ({ locationState }) => {
  const [activeTab, setActiveTab] = useState(
    locationState?.status || dataSource[0].type
  );

  useEffect(() => {
    locale("en");
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "applied":
        return <AllTasks />;
      case "accepted":
        return <AcceptedTasks />;
      case "in-progress":
        return <InProgress />;
      case "completed":
        return <CompletedTasks />;
      case "paid":
        return <PaidTasks />;
      default:
        return <AllTasks />;
    }
  };

  return (
    <Container css={tw`mt-4`}>
      {/* <TabPanel dataSource={dataSource} swipeEnabled keyExpr='index' /> */}
      {/* <TabCardGrid /> */}
      <HeaderRow>
        {/* <Button
          text='Create New Task'
          type='default'
          tw='rounded-full sm:mb-0 mb-4'
          icon='pi pi-plus'
        /> */}
        <TabsControl>
          {dataSource.map((data) => (
            <TabControl
              key={data.index}
              active={activeTab === data.type}
              onClick={() => setActiveTab(data.type)}
            >
              {data.title}
            </TabControl>
          ))}
        </TabsControl>
      </HeaderRow>
      {renderContent()}
    </Container>
  );
};

export default Tasks;
