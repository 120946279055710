import "styled-components/macro";
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import tw from "twin.macro";

const AdjacentPostCard = ({ post, position }) => (
  <>
    <div
      css={tw`absolute  bg-center bg-no-repeat bg-cover shadow-md inline-block w-full h-72`}
      style={{ backgroundImage: `url('${post.featuredImage.url}')` }}
    />
    <div
      css={tw`absolute  bg-center bg-gradient-to-b opacity-50 from-gray-400 via-gray-700 to-black w-full h-72`}
    />
    <div
      css={tw`flex flex-col  p-4 items-center justify-center absolute w-full h-full`}
    >
      <p css={tw`text-white font-semibold text-xs`} className='text-shadow'>
        {moment(post.createdAt).format("MMM DD, YYYY")}
      </p>
      <p
        css={tw`text-white font-semibold text-2xl text-center`}
        className='text-shadow '
      >
        {post.title}
      </p>
    </div>
    <Link to={`/post/${post.slug}`}>
      <span css={tw`z-10 cursor-pointer absolute w-full h-full`} />
    </Link>
    {position === "LEFT" && (
      <div
        css={tw`absolute  text-center py-3 cursor-pointer bg-primary-600  rounded-full`}
        className='arrow-btn right-4 bottom-5 '
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          css={tw`h-6 w-6 text-white w-full`}
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M10 19l-7-7m0 0l7-7m-7 7h18'
          />
        </svg>
      </div>
    )}
    {position === "RIGHT" && (
      <div
        css={tw`absolute text-center py-3 cursor-pointer bg-primary-600 rounded-full`}
        className='arrow-btn right-4 bottom-5 '
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          css={tw`h-6 w-6 text-white w-full`}
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M14 5l7 7m0 0l-7 7m7-7H3'
          />
        </svg>
      </div>
    )}
  </>
);

export default AdjacentPostCard;
