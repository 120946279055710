import Wallet from "components/wallet/Wallet";
import { Heading } from "./Profile";

const WalletPage = () => {
  return (
    <div>
      <Heading>Wallet</Heading>
      <Wallet />
    </div>
  );
};

export default WalletPage;
