import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../service/authService";
import { removeUserFromLocalStorage, setUserToLocalStorage } from "utils";
import { showNotification } from "utils/notification";

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  // const [loading, setLoading] = useState(true);
  const { push } = useHistory();

  const getUser = async (isErrorNeeded = false) => {
    try {
      const {
        data: { data },
      } = await AuthService.getUser();
      setUser(data);
    } catch (error) {
      if (isErrorNeeded) {
        showNotification(error, "error");
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const signIn = useCallback(async (loginDetails) => {
    try {
      const {
        data: { token, data },
      } = await AuthService.loginUser(loginDetails);
      setUserToLocalStorage(token);
      setUser(data);
      setToken(token);
    } catch (error) {
      showNotification(error, "error");
    }

    // eslint-disable-next-line
  }, []);

  const logout = useCallback(async () => {
    try {
      await AuthService.logout();
      removeUserFromLocalStorage();
      setUser(null);
      push("/");
    } catch (error) {
      showNotification(error, "error");
    }

    // eslint-disable-next-line
  }, []);

  const createUser = useCallback(async (accDetails) => {
    try {
      await AuthService.createUser(accDetails);
      showNotification("Account created successfully", "success");
      push({ pathname: "/login", state: { to: "/profile/settings" } });
    } catch (error) {
      showNotification(error, "error");
    }

    // eslint-disable-next-line
  }, []);

  const requestResetPassword = useCallback(async (email) => {
    try {
      await AuthService.requestResetPassword(email);
      showNotification(
        "We've sent a link to reset your password. Check your inbox.",
        "success",
        9000
      );
    } catch (error) {
      showNotification(error, "error");
    }
  }, []);

  const resetPassword = useCallback(
    async (password, token) => {
      try {
        await AuthService.resetPassword(password, token);
        showNotification("Password reset was successful", "success");
        push({ pathname: "/login" });
      } catch (error) {
        showNotification(error, "error");
      }
    },
    [push]
  );

  return (
    <React.Fragment>
      <AuthContext.Provider
        value={{
          user,
          signIn,
          logout,
          createUser,
          getUser,
          setUser,
          requestResetPassword,
          resetPassword,
          token,
        }}
        {...props}
      />
    </React.Fragment>
  );
};

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
