import React, { useEffect } from 'react';
import { LoadingContainer } from './Profile';
import AuthService from 'service/authService';
import { CLIENT_URL } from 'constant';
import { setUserToLocalStorage } from 'utils';
import { LoadPanel } from 'devextreme-react';

const AuthUser = () => {
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      getUser(token);
    } else {
      window.location.replace(`${CLIENT_URL}/login`);
    }
  }, []);

  const getUser = async (token) => {
    try {
      await AuthService.getUser(token);
      setUserToLocalStorage(token);
      window.location.replace(`${CLIENT_URL}`);
    } catch (error) {
      window.location.replace(`${CLIENT_URL}/login`);
    }
  };

  return (
    <LoadingContainer>
      <LoadPanel visible closeOnOutsideClick={false} />
    </LoadingContainer>
  );
};

export default AuthUser;
