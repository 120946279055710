import API from "../api/apiConfig";

const ProfileService = {
  createProfile: async (profileData) => {
    return await API.post(`/user/profile`, profileData);
  },
  updateProfile: async (profileData, id) => {
    return await API.put(`/user/profile/${id}`, profileData);
  },
  deleteFile: async (id, justFile) => {
    if (justFile) {
      return await API.delete(`/user/profile/file/${id}`, {
        params: { justFile: true },
      });
    } else {
      return await API.delete(`/user/profile/file/${id}`);
    }
  },
};

export default ProfileService;
