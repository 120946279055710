import API from "../api/apiConfig";

export const jobServiceQueryKeys = {
  GET_ALL_JOBS: "GET_ALL_JOBS",
  GET_JOB_STATUS: "GET_JOB_STATUS",
  GET_JOB_DETAILS: "GET_JOB_DETAILS",
};

const JobService = {
  getAllJobs: async (status) => {
    if (status) {
      const { data } = await API.get("/job", {
        params: { status },
      });

      return data;
    } else {
      const { data } = await API.get("/job");

      return data;
    }
  },

  getSingleJob: async (id) => {
    const { data } = await API.get(`/job/${id}`);
    return data;
  },

  applyToJob: async (applicationData, jobId) => {
    return await API.post(`/job/${jobId}/apply`, applicationData);
  },

  startJob: async (requestData) => {
    return await API.post("/user/profile/job/start", requestData);
  },

  addAttendance: async (requestData) => {
    return await API.post("/user/profile/job/attendance", requestData);
  },

  completeJob: async (jobId) => {
    return await API.post("/user/profile/job/complete", { jobId });
  },

  deleteJob: async (jobCode, jobType) => {
    return await API.delete(`/user/profile/job/${jobCode}/${jobType}`);
  },
};

export default JobService;
