import "styled-components/macro";
import React from "react";
import PostWidget from "./PostWidget";
import { LoadPanel } from "devextreme-react";
import { useParams } from "react-router-dom";
import usePostDetails from "hooks/usePostDetails";
import PostDetail from "./PostDetail";
import AdjacentPosts from "./AdjacentPosts";
import tw from "twin.macro";

const PostDetails = () => {
  const { slug } = useParams();

  const { isLoading, data, isError } = usePostDetails(slug);

  if (isLoading) {
    return <LoadPanel visible />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div css={tw`container mx-auto mb-8`}>
        <div css={tw`grid grid-cols-1 lg:grid-cols-12 gap-12`}>
          <div css={tw`col-span-1 lg:col-span-8`}>
            <PostDetail post={data} />
            <AdjacentPosts slug={data.slug} createdAt={data.createdAt} />
          </div>
          <div css={tw`col-span-1 lg:col-span-4`}>
            <div css={tw`relative lg:sticky`} className='top-8'>
              <PostWidget
                slug={data.slug}
                categories={data.categories.map((category) => category.slug)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
