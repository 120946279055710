import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import ScheduleImage from "images/calender.svg";
import PaymentImage from "images/payment.svg";

const Container = tw.div`relative`;

export const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

export const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: `taskpip does all security and background checks for you.
        So, either you are offering jobs or looking for work,
        we have done the background checks for everyone who owns a profile in our system.`,
    },
    {
      imageSrc: ReliableIconImage,
      title: "Reliable Earnings ",
      description:
        "Make money, keep your tips, and use web-app tools to help maximize your earnings. ",
    },
    {
      imageSrc: ScheduleImage,
      title: "A Flexible Schedule",
      description: `Working with us makes you your own boss in away, because you have the
        opportunity of choosing when to work or declined a work if available on our platform.`,
    },
    {
      imageSrc: PaymentImage,
      title: "Get Paid Bi-weekly",
      description: `Cash out your earnings bi-weekly or
      request for an advance payment before pay day.
      An advance payment comes with a fee attached to it. `,
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description:
        "With 24/7 customer support, you can be rest assured that we will never leave you hanging every step of the way. ",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable",
      description:
        "Our easy-to-use interface makes it easy to input work details and allows work seekers to select the perfect work ",
    },

    {
      imageSrc: FastIconImage,
      title: "Fast",
      description:
        "From inputting work details to the final pay-out, our automated system makes sure every step is completed fast and secured. ",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Easy",
      description:
        "Our interface has been designed for your ease and comfort. ",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className='imageContainer'>
                <img src={card.imageSrc || defaultCardImage} alt='' />
              </span>
              <span className='textContainer'>
                <span className='title'>{card.title || "Fully Secure"}</span>
                <p className='description'>
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
