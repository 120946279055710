import "styled-components/macro";
import React, { useState } from "react";
import {
  ThreeColumnContainer,
  DecoratorBlob,
} from "components/features/ThreeColWithSideImage";
import { Container } from "components/misc/Layouts";
import { Subheading } from "components/misc/Headings";
import { useQuery } from "react-query";
import { LoadPanel } from "devextreme-react";
import JobService, { jobServiceQueryKeys } from "service/jobService";
import tw from "twin.macro";
import ad from "images/ad.svg";
import Image from "components/misc/Image";
import { Button } from "devextreme-react";
import { Flex, sumJobHours } from "./Jobs";
import { Link, useParams, useHistory } from "react-router-dom";
import RichTextEditor from "react-rte";
import { showNotification } from "utils/notification";
import JobApplication from "./JobApplication";
import { useAuth } from "context/auth";
import { alert } from "devextreme/ui/dialog";
// import Alert from "components/misc/Alert";
import { Message } from "primereact/message";

const DetailsContainer = tw.div`grid lg:grid-cols-2 grid-cols-1 gap-4 text-[15px]!`;
const SummarySection = tw.div`rounded-md border shadow-lg border-primary-500 p-4 lg:col-span-2`;
const SummaryItem = tw.div`flex flex-col space-y-6`;
const MainDetailsSection = tw.div`rounded-md border shadow-lg border-primary-500 p-4 lg:col-span-2`;
const Item = tw.div`flex flex-col space-y-1`;

const JobDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const { push } = useHistory();
  const { user } = useAuth();
  const { data, isError, error, isLoading } = useQuery(
    jobServiceQueryKeys.GET_JOB_DETAILS,
    async () => JobService.getSingleJob(id),
    {
      enabled: id ? true : false,
      retry: false,
    }
  );

  if (isError) {
    showNotification(error, "error");
  }

  const closeApplicationModal = () => {
    setIsOpen(false);
  };

  const goToApplication = () => {
    if (!user) {
      push({ pathname: "/login", state: { from: `/task/${id}` } });
      return;
    }

    if (!user?.profile) {
      alert(
        "Please complete your profile to start applying to tasks",
        "Notification"
      );
      return;
    }

    if (user?.profile && user.profile.profileStatus === "pending") {
      alert(
        "Sorry, you can not apply to tasks. Profile is still under verification. Please contact the support team for more information.",
        "Notification"
      );
      return;
    }

    if (user?.profile && user.profile.profileStatus === "de-activated") {
      alert(
        "Sorry, you can not apply to tasks. Profile is currently de-activated. Please contact the support team for more information.",
        "Notification"
      );
      return;
    }

    const isApplied = data.data.applicants.find(
      (e) => e.userInfo.userId === user._id
    );

    if (isApplied) {
      alert("You already applied to this task.", "Notification");
      return;
    }

    setIsOpen(true);
  };

  return (
    <Container>
      <ThreeColumnContainer css={tw`py-8!`}>
        {!user ? (
          <Message
            severity='info'
            css={tw`w-full justify-start mt-4`}
            text={() => (
              <span>
                Please{" "}
                <Link
                  to={{ pathname: "/login", state: { from: `/task/${id}` } }}
                  css={tw`text-blue-600 hover:underline`}
                >
                  Login into your account
                </Link>{" "}
                or{" "}
                <Link
                  to={{ pathname: "/sign-up", state: { from: `/task/${id}` } }}
                  css={tw`text-blue-600 hover:underline`}
                >
                  Create a new account
                </Link>{" "}
                to start applying to Jobs
              </span>
            )}
          />
        ) : (
          <>
            {!user?.profile ? (
              <Message
                severity='info'
                css={tw`w-full justify-start mt-12`}
                text={() => (
                  <span>
                    Please complete your profile to start applying to tasks. Go
                    to{" "}
                    <Link
                      to='/profile/settings'
                      css={tw`text-blue-600 hover:underline`}
                    >
                      Profile Setting
                    </Link>
                  </span>
                )}
              />
            ) : null}

            {user?.profile && user.profile.profileStatus === "pending" ? (
              <Message
                severity='info'
                css={tw`w-full justify-start mt-12`}
                text='Sorry, you can not apply to tasks. Profile is still under verification. Please contact support for more information.'
              />
            ) : null}

            {user?.profile && user.profile.profileStatus === "de-activated" ? (
              <Message
                severity='info'
                css={tw`w-full justify-start mt-12`}
                text='Sorry, you can not apply to tasks. Profile is currently de-activated. Please contact the support team for more information.'
              />
            ) : null}
          </>
        )}
      </ThreeColumnContainer>

      <div css={tw`max-w-screen-lg mx-auto`}>
        <Button
          type='default'
          icon='back'
          text='All Tasks'
          css={tw`rounded-full!`}
          onClick={() => push("/tasks")}
        />
      </div>

      <ThreeColumnContainer css={tw`py-12!`}>
        <Subheading css={tw`uppercase mb-6`}>Task Details</Subheading>

        {isLoading && <LoadPanel visible />}
        {!isError && data?.data ? (
          <DetailsContainer>
            <SummarySection>
              <SummaryItem>
                <Item>
                  <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                    Gross Payment
                  </h5>
                  <Flex>
                    <i
                      className='pi pi-money-bill'
                      css={tw`text-primary-500 text-lg`}
                    ></i>
                    <span css={tw`lg:text-left text-center`}>
                      <strong>
                        {sumJobHours(data.data.taskDays) *
                          data.data.priceByHour}{" "}
                        C$
                      </strong>{" "}
                      - {data.data.priceByHour} C$/h
                    </span>
                  </Flex>
                </Item>
                <Item>
                  <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                    Task Location
                  </h5>
                  <Flex>
                    <i
                      className='pi pi-map-marker'
                      css={tw`text-primary-500 text-lg lg:text-left text-center`}
                    ></i>
                    <span
                      css={tw`lg:text-left text-center`}
                    >{`${data.data.location.addressLine}, ${data.data.location.city}, ${data.data.location.province} ${data.data.location.country}, ${data.data.location.postalCode}.`}</span>
                  </Flex>
                </Item>
                <Item>
                  <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                    Date and Time
                  </h5>

                  {data.data.taskDays.map((day) => {
                    return (
                      <Flex
                        css={tw`mb-2! lg:gap-4 lg:text-left text-center`}
                        key={day.id}
                      >
                        <i
                          className='pi pi-clock'
                          css={tw`text-primary-500 text-lg lg:text-left text-center`}
                        ></i>

                        <span>{day.date}</span>

                        <span>
                          {day.startTime} - {day.endTime} ({day.numberOfHours}
                          hrs)
                        </span>
                      </Flex>
                    );
                  })}
                </Item>
              </SummaryItem>
            </SummarySection>
            <MainDetailsSection>
              <Flex>
                <i className='pi pi-tag' css={tw`text-primary-500 text-lg`}></i>
                <Subheading css={tw`text-xl`}>{data.data.title}</Subheading>
              </Flex>

              <Image src={data.data.jobImgUrl || ad} />
              <div css={tw`my-4`}>
                <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                  Task Poster
                </h5>
                <Flex css={tw`justify-between mb-3`}>
                  <Flex>
                    <i
                      className='pi pi-building'
                      css={tw`text-primary-500 text-lg`}
                    ></i>
                    <h5>{data.data.jobPosterName}</h5>
                  </Flex>
                  {/* <span tw='cursor-pointer text-primary-700 hover:underline'>
                  About the employer
                </span> */}
                </Flex>
              </div>
              <div>
                <h5 css={tw`font-bold mb-1 lg:text-left text-center`}>
                  Task Description
                </h5>
                <RichTextEditor
                  readOnly
                  value={RichTextEditor.createValueFromString(
                    data.data.fullDescription,
                    "markdown"
                  )}
                  editorClassName='editor'
                />
              </div>
              <Button
                text='Apply to this task'
                css={tw`w-full mt-6 !rounded-full`}
                type='default'
                onClick={goToApplication}
              />
            </MainDetailsSection>
          </DetailsContainer>
        ) : null}
      </ThreeColumnContainer>
      <DecoratorBlob />
      {data?.data && (
        <JobApplication
          visible={isOpen}
          onHide={closeApplicationModal}
          jobDetails={data.data}
        />
      )}
    </Container>
  );
};

export default JobDetails;
