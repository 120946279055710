import "styled-components/macro";
import StatusCard from "components/cards/StatusCard";
import { Button } from "devextreme-react";
import Form, {
  SimpleItem,
  GroupItem,
  RequiredRule,
  PatternRule,
} from "devextreme-react/form";
import { useRef } from "react";
import AuthService from "service/authService";
import tw from "twin.macro";
import { showNotification } from "utils/notification";

const formData = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const GeneralSettings = () => {
  const formRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResult = formRef.current.instance.validate();

    if (validationResult && !validationResult.isValid) {
      return;
    }

    try {
      await AuthService.updatePassword({
        currentPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      });
      showNotification("Password updated successfully", "success");
      formRef.current.instance.resetValues();
    } catch (error) {
      showNotification(error, "error");
    }
  };

  return (
    <div css={tw`flex justify-center items-center mt-4`}>
      <form css={tw`w-full`} onSubmit={onSubmit}>
        <StatusCard css={tw`lg:w-1/2 w-full block space-x-0`}>
          <Form
            formData={formData}
            width={"100%"}
            labelLocation='top'
            labelMode='floating'
            ref={formRef}
          >
            <GroupItem colCount={1} caption='Change Password'>
              <SimpleItem
                dataField='oldPassword'
                editorOptions={{
                  mode: "password",
                  inputAttr: { autocomplete: "old-password" },
                }}
              >
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='newPassword'
                editorOptions={{
                  mode: "password",
                  inputAttr: { autocomplete: "new-password" },
                }}
              >
                <RequiredRule />
                <PatternRule
                  pattern={/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/}
                  message='Password must be at least 8 characters long and alphanumeric'
                />
              </SimpleItem>
            </GroupItem>
          </Form>
          <Button
            type='default'
            text='Confirm'
            css={tw`!rounded-full w-full`}
            useSubmitBehavior
          />
        </StatusCard>
      </form>
    </div>
  );
};

export default GeneralSettings;
