import Tasks from "components/tasks/Tasks";
import { Heading } from "./Profile";
import { useHistory } from "react-router-dom";

const TasksPage = () => {
  const {
    location: { state },
  } = useHistory();
  return (
    <div>
      <Heading>Tasks</Heading>
      <Tasks locationState={state} />
    </div>
  );
};

export default TasksPage;
